import React from "react";

const Modal = ({ id, title, children, onClose }) => (
  <>
    <div id={id} className="modal" onClick={onClose}></div>
    <div className="bg-[#F9FAFB] border border-[#E5E7EB] 2xl:p-8 xl:p-6 p-5 max-w-[401px] ml-auto fixed inset-2 overflow-auto z-[9999]">
      <div className="border-b border-[#E5E7EB] mb-8 pb-8">
        <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">{title}</h2>
      </div>
      {children}
    </div>
  </>
);

export default Modal;
