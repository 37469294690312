import React from "react";
import Table from "./Structures/Table";

const parseReportData = (report) => {
  // Default parsedData structure
  let parsedData = {
    lobbyists: [],
    companies: [],
    newContracts: [],
    terminatedContracts: [],
  };

  // Check if report is undefined or not a string
  if (typeof report !== "string" || report.trim() === "") {
    return parsedData;
  }

  // Proceed with parsing if report is valid
  const sections = report.split(/\n(?=New |Terminated )/);

  sections.forEach((section) => {
    if (section.startsWith("New Lobbyists:")) {
      parsedData.lobbyists = section
        .split("\n")
        .slice(1)
        .filter((line) => line.startsWith("-"))
        .map((line) => line.slice(2));
    } else if (section.startsWith("New Companies:")) {
      parsedData.companies = section
        .split("\n")
        .slice(1)
        .filter((line) => line.startsWith("-"))
        .map((line) => line.slice(2));
    } else if (section.startsWith("New Contracts:")) {
      parsedData.newContracts = section
        .split("\n")
        .slice(1)
        .filter((line) => line.trim() && !line.startsWith("Terminated Contracts:"))
        .map((contract) => {
          const parts = contract.split(", ").map((part) => part.split(": ").pop());
          return {
            lobbyist: parts[0],
            company: parts[1],
          };
        });
    } else if (section.startsWith("Terminated")) {
      parsedData.terminatedContracts = section
        .split("\n")
        .slice(1)
        .filter((line) => line.trim())
        .map((contract) => {
          const parts = contract.split(", ").map((part) => part.split(": ").pop());
          return {
            lobbyist: parts[0],
            company: parts[1],
          };
        });
    }
  });
  console.log(parsedData.terminatedContracts);

  return parsedData;
};

const LobbyReportTable = ({ report }) => {
  const { lobbyists, companies, newContracts, terminatedContracts } = parseReportData(report);

  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="grid grid-cols-2 gap-6 w-full h-[calc(100vh-330px)] overflow-y-auto">
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5">
        <div className="border-b border-[#E5E7EB] mb-3 pb-2.5 flex flex-col justify-between">
          <h2 className="font-playfair text-[24px] text-darkblue font-medium leading-8">
            Daily Lobby Report for {getYesterdayDate()}
          </h2>
        </div>
        <h6 className="mb-2">This report is generated off of yesterdays filings.</h6>
        <div className="reportContent">
          <h2 className="font-playfair text-[18px] text-darkblue font-medium leading-8">New Lobbyists</h2>
          <ul className="lobbyList">
            {lobbyists &&
              lobbyists.length > 0 &&
              lobbyists.map((lobbyist, index) => (
                <li className="lobbyItem" key={index}>
                  {lobbyist}
                </li>
              ))}
          </ul>

          <h2 className="font-playfair text-[18px] text-darkblue font-medium leading-8 mt-2">New Companies</h2>
          <ul className="companyList">
            {companies &&
              companies.length > 0 &&
              companies.map((company, index) => (
                <li className="companyItem" key={index}>
                  {company}
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5">
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5 flex flex-col justify-between">
          <h2 className="font-playfair text-[24px] text-darkblue font-medium leading-8">New Contracts</h2>
        </div>
        <Table
          headers={["Lobbyist", "Company"]}
          rows={newContracts.map((contract) => [contract.lobbyist, contract.company])}
          loading={false}
        />
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5 flex flex-col justify-between mt-5">
          <h2 className="font-playfair text-[24px] text-darkblue font-medium leading-8">Terminated Contracts</h2>
        </div>
        <Table
          headers={["Lobbyist", "Company"]}
          rows={terminatedContracts.map((contract) => [contract.lobbyist, contract.company])}
          loading={false}
        />
      </div>
    </div>
  );
};

export default LobbyReportTable;
