import React, { useState, useEffect } from "react";
// Components
import { SearchBar, ButtonDatePicker } from "../videoIndex";
import Dropdown from "../Structures/Dropdown";
// Third party
import axios from "axios";
// Utils
import { IoClose } from "react-icons/io5";
// store
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllFilters,
  setFilterChamber,
  setFilterCommittee,
  setFilterDate,
  setFilterLegislature,
} from "../../features/video/videoSlice";
import ThreeArrowsSVG from "../SVGs/ThreeArrowsSVG";

const FiltersContainer = ({ searchRes, setSearchRes, setLoading }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [committeeList, setCommitteeList] = useState([""]);

  // Access the filter state from video slice
  const { date, chamber, committee, legislature } = useSelector((state) => state.video.filter);

  const dispatch = useDispatch();

  useEffect(() => {
    getCommitteeList();
    handleSearch(searchTerm);
  }, [date, chamber, committee, legislature]);

  const handleSearch = async (term) => {
    try {
      if (term) {
        let api_url = `/api/video/search?term=${term}`;
        setLoading(true);

        if (date) {
          api_url = api_url + `&month=${date.getMonth() + 1}&year=${date.getFullYear()}`;
        }

        if (chamber) api_url += `&chamber=${chamber}`;

        if (committee) {
          api_url += `&committee=${committee}`;
        }

        if (legislature) api_url += `&legislature=${legislature}`;

        const { data } = await axios.get(api_url);
        setSearchRes(data);
        setLoading(false);
      } else setSearchRes(null);
    } catch (error) {
      console.log(error);
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleChamberChange = (e) => {
    dispatch(setFilterChamber(e.target.value));
  };

  const getCommitteeList = async () => {
    try {
      const { data } = await axios.get("/api/video/committees");

      setCommitteeList(
        data.data.map((item) => ({
          label: item.CommitteeName,
          value: item.Code,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const clearFilters = () => {
    dispatch(clearAllFilters());
    clearSearch();
  };

  const handleChangeCommittee = (committee) => {
    dispatch(setFilterCommittee(committee));
  };

  const getLegislatureList = () => ["24R", "241", "242"];

  const handleChangeLegislature = (legislature) => {
    dispatch(setFilterLegislature(legislature));
  };

  return (
    <div className="bg-white border border-[#E5E7EB] 2xl:p-5 xl:p-5 p-5 w-full">
      <div className="border-b border-[#E5E7EB] mb-3.5 pb-3.5 flex flex-col md:flex-row max-md:gap-4 items-center justify-between">
        <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
          Committee Videos
        </h2>
      </div>
      <div className="flex items-center gap-[15px]">
        <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black">
          Search
          <ThreeArrowsSVG />
        </div>
        <div className="flex flex-col lg:flex-row w-full items-center gap-[15px]">
          <SearchBar
            handleSearch={handleSearch}
            search={searchTerm}
            setSearch={setSearchTerm}
            placeholder="AI powered Search..."
          />
          <div className="flex flex-col gap-[15px]">
            <ButtonDatePicker startDate={date} setStartDate={(newDate) => dispatch(setFilterDate(newDate))} />
          </div>
          <Dropdown
            title="Committee"
            options={committeeList}
            onChange={(e) => handleChangeCommittee(e.target.value)}
            value={committee}
          />
          <Dropdown
            title="Legislature"
            options={getLegislatureList()}
            onChange={(e) => handleChangeLegislature(e.target.value)}
            value={legislature}
          />

          <div className="flex items-center gap-[15px]">
            <div className={`flex items-center p-2 border border-gray-200 `}>
              <input
                checked={chamber === "S"}
                id="chamber-radio-1"
                type="radio"
                value="S"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300  cursor-pointer"
                onChange={handleChamberChange}
              />
              <label htmlFor="chamber-radio-1" className="w-full ms-2 text-sm font-medium text-gray-500 ">
                Senate
              </label>
            </div>
            <div className={`flex items-center p-2 border border-gray-200  `}>
              <input
                checked={chamber === "H"}
                id="chamber-radio-2"
                type="radio"
                value="H"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer "
                onChange={handleChamberChange}
              />
              <label htmlFor="chamber-radio-2" className="w-full ms-2 text-sm font-medium text-gray-500 ">
                House
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button
              type="button"
              className="py-2 px-12 bg-[#83B2FA] text-base font-normal text-white transition-all duration-300 hover:bg-lightblue-hover"
              onClick={() => handleSearch(searchTerm)}
            >
              Search
            </button>
            {(date || chamber || searchRes?.length > 1 || committee || legislature) && (
              <button
                onClick={clearFilters}
                className="bg-gray-200 text-gray-500  flex gap-2 items-center py-2 px-2 text-base font-normal"
              >
                Clear
                <IoClose />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersContainer;
