import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import MainHeader from "./MainHeader";
import SimpleFooter from "../Structures/SimpleFooter";
import { useLocation, Outlet, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setState, reset as userReset, setRedirectUrl } from "../../features/user/userSlice";
import { reset as billReset } from "../../features/bills/billSlice";
import stateConfig from "../../stateConfig";
import "../../styles/Dashboard.css";

const changeFavicon = (iconURL) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = iconURL;
};

const Layout = () => {
  const { searchTerm, state } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [canAccess, setCanAccess] = useState(user.auth ? true : false);

  useEffect(() => {
    const prefix = state.toLowerCase();
    const config = stateConfig[prefix];
    changeFavicon(config.icon);
    document.title = config.tabTitle;
    dispatch(setRedirectUrl(config.website));
  }, [state]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const user = JSON.parse(localStorage.getItem("user"));

      if (!user || !user.token) return;

      try {
        const decodedToken = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          console.error("Token has expired");
          localStorage.removeItem("user");
          if (user.auth !== "login") window.location.href = `/${user.state}/search/${user.searchTerm}`;
          else navigate("/");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem("user");
      }
    };

    checkTokenExpiration();
  }, []);

  useEffect(() => {
    runAuth();
  }, [user, isLoading]);

  const runAuth = async () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const basePath = pathSegments[1]?.toLowerCase();

    const isBillPage = basePath === "bills";

    const isLobbyRelatedPage =
      basePath === "lobby" ||
      basePath === "lobbyist" ||
      basePath === "company" ||
      basePath === "contactme" ||
      basePath === "taskmaster";

    if (isLobbyRelatedPage) {
      setCanAccess(true);
      if (!user.auth) dispatch(setState(state));
      return;
    } else if (!user?.auth && searchTerm && !isLoading && (isBillPage || !isLobbyRelatedPage)) {
      setIsLoading(true);
      try {
        const data = await dispatch(getUser(searchTerm)).unwrap();
        if (!data?.auth) {
          console.log("UNAUTHENTICATED ", data?.auth);
          window.location.href = user.redirectUrl;
        }
        setIsLoading(false);
        setCanAccess(true);
      } catch (error) {
        console.log(error);
        window.location.href = user.redirectUrl;
      }
    } else if (!user?.auth && !searchTerm && !isLoading && !isLobbyRelatedPage) {
      window.location.href = user.redirectUrl;
    }

    if (user.state && state.toUpperCase() !== user.state.toUpperCase() && !isLobbyRelatedPage) {
      if (searchTerm.includes(state.toUpperCase())) {
        localStorage.removeItem("user");
        localStorage.removeItem("matches");
        dispatch(userReset());
        dispatch(billReset());
      } else {
        setCanAccess(false);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-[calc(100vh)]">
        <MainHeader />
        {canAccess && (
          <div className="flex-grow bg-white w-full xl:p-[20px] p-[15px] xl:mt-[75px] mt-10 pb-10 relative overflow-y-auto">
            <Outlet />
          </div>
        )}
        <SimpleFooter />
      </div>
    </>
  );
};

export default Layout;
