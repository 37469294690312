import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import billService from "./billService";

const matchesFromLocalStorage = localStorage.getItem("matches");

const initialState = {
  bills: matchesFromLocalStorage ? JSON.parse(matchesFromLocalStorage) : [],
  bill: [],
  billSummary: {},
  queries: [],
  versions: [],
  companies: [],
  comparison: {},
  report: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

export const getMatches = createAsyncThunk(
  "bill/getMatches",
  async ({ query, numResults, minPercentage, session }, thunkAPI) => {
    try {
      // Call your API service with all parameters
      const matches = await billService.getMatches({
        query,
        numResults,
        minPercentage,
        session,
      });
      return matches; // This will be available as action.payload in your component
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSections = createAsyncThunk("bill/getSections", async ({ billId, session, version }, thunkAPI) => {
  try {
    // Call your API service with all parameters
    const sections = await billService.getSections({
      billId,
      session,
      version,
    });
    return sections; // This will be available as action.payload in your component
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getQueryResponse = createAsyncThunk(
  "bill/getQueryResponse",
  async ({ auth, query, billText, billId, mode, version }, thunkAPI) => {
    try {
      const response = await billService.getQueryResponse({
        auth,
        query,
        billText,
        billId,
        mode,
        version,
      });
      return { query, response: response }; // Assuming the API response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getVersions = createAsyncThunk("bill/getVersions", async (billId, thunkAPI) => {
  try {
    console.log(billId);
    const response = await billService.getVersions(billId);
    return response; // Assuming the API response structure
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getComparison = createAsyncThunk("bill/getComparison", async ({ billId, versions }, thunkAPI) => {
  try {
    const response = await billService.getComparison({ billId, versions });
    return response; // Assuming the API response structure
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCompanies = createAsyncThunk("bill/getCompanies", async (_, thunkAPI) => {
  try {
    console.log("I AM GETTING THEM!");
    const response = await billService.getCompanies();
    return response; // Assuming the API response structure
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const compileReport = createAsyncThunk(
  "bill/compileReport",
  async ({ company, billNumber, version }, thunkAPI) => {
    try {
      console.log(company);
      console.log(billNumber);
      const response = await billService.compileReport({
        company,
        billNumber,
        version,
      });
      return response; // Assuming the API response structure
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const redoSection = createAsyncThunk("bill/redoSection", async ({ company, billNumber, version }, thunkAPI) => {
  try {
    console.log(company);
    console.log(billNumber);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const clearQuery = createAsyncThunk("bill/clearQuery", async ({ auth, mode }, thunkAPI) => {
  try {
    console.log(auth);
    console.log(mode);
    const response = await billService.clearQuery({
      auth,
      mode,
    });
    return response; // Assuming the API response structure
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    reset: (state) => {
      state.bills = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
    clearQueries: (state) => {
      state.queries = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMatches.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getMatches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.bills = action.payload;
        localStorage.setItem("matches", JSON.stringify(action.payload));
        state.message = null;
      })
      .addCase(getMatches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSections.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.pdf = null;
        state.message = null;
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.bill = action.payload.sections;
        state.pdf = action.payload.pdfUrl;
        state.versions = action.payload.versions;
        state.billSummary = action.payload.billSummary;
        state.message = null;
      })
      .addCase(getSections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getQueryResponse.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getQueryResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.queries.push({
          query: action.payload.query,
          response: action.payload.response,
        });
        state.message = null;
      })
      .addCase(getQueryResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(clearQuery.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(clearQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.queries = [];
        state.message = null;
      })
      .addCase(clearQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getVersions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVersions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.versions = action.payload.versions; // Ensure your API responds with an object that has a versions key
        state.message = null;
      })
      .addCase(getVersions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(getComparison.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getComparison.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.comparison = action.payload; // Ensure your API responds with an object that has a versions key
        state.message = null;
      })
      .addCase(getComparison.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.companies = action.payload; // Ensure your API responds with an object that has a versions key
        state.message = null;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(compileReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(compileReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.report = action.payload.analysis; // Ensure your API responds with an object that has a versions key
        state.message = null;
      })
      .addCase(compileReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      });
  },
});

export const { reset, clearQueries } = billSlice.actions;
export default billSlice.reducer;
