export function isToday(date) {
  const givenDate = new Date(date).toISOString().slice(0, 10);
  const today = new Date().toISOString().slice(0, 10);

  return givenDate === today;
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  if (hours > 0) {
    return `${hours}hr ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
}

export const getBaseURL = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
};
