import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryResponse, clearQuery } from "../features/bills/billSlice";
import { BounceLoader as Spinner } from "react-spinners";
import axios from "axios";
import ThumbsUpIcon from "./QueryFuncs/ThumbsUpIcon";
import ThumbsDownIcon from "./QueryFuncs/ThumbsDownIcon";
import PrintQueryLog from "./QueryFuncs/PrintQueryLog";
import recommendedQueries from "./QueryFuncs/RecommendedQueries";

const BillQueryForm = ({ billText, version, billId, keyChanges, isQueryDisabled }) => {
  const { queries } = useSelector((state) => state.bill);
  const user = useSelector((state) => state.user);
  const auth = user.searchTerm;
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeButtons, setActiveButtons] = useState({});
  const [notification, setNotification] = useState("");

  const dispatch = useDispatch();

  const handleFeedback = async (index, query, response, isPositive) => {
    setActiveButtons((prev) => ({
      ...prev,
      [index]: isPositive ? "up" : "down",
    }));

    const feedback = {
      query: `The following is a bill text: ${billText}\n\nQ: ${query}`,
      response: response,
      isPositive: isPositive,
      mode: "precision",
    };

    try {
      const response = await axios.post("/api/bills/submit-feedback", feedback);
      console.log(response.data.message);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  useEffect(() => {
    dispatch(clearQuery({ auth, mode: "precision" }));
  }, [billText, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (notification.length > 0) {
      setNotification("");
    }

    // Check if the query is to clear the existing queries
    if (query.trim().toLowerCase() === "clear") {
      dispatch(clearQuery(auth));
      setQuery("");
      return;
    }

    setIsLoading(true); // Start loading

    // Use keyChanges if billText is too long, otherwise use billText
    const textToSend = billText.length > 50000 ? keyChanges : billText;

    await dispatch(
      getQueryResponse({
        auth,
        query,
        billText: textToSend,
        billId,
        mode: "precision",
        version,
      })
    )
      .unwrap()
      .catch((error) => console.error("Failed to fetch query response:", error))
      .finally(() => setIsLoading(false)); // End loading regardless of the outcome

    setQuery(""); // Reset query input after submission
  };

  const handleSelectRecommendedQuery = (queryText) => {
    setQuery(queryText);
  };

  // Function to process and return text with bolded words and numbered list
  const renderResponseWithBold = (response) => {
    // First, split the response into parts based on the pattern **bold** and numbers followed by periods
    const parts = response.split(/(\*\*[^*]+\*\*)|(\d+\.\s)/g); // This regex captures both patterns

    return parts.map((part, index) => {
      if (!part) return null; // Filter out any null or undefined parts that may result from split

      // Check for bold pattern
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove ** and wrap in <strong>
      }

      // Check for number followed by a period, indicating a new list item
      if (/\d+\.\s/.test(part)) {
        return (
          <React.Fragment key={index}>
            <br />
            <br />
            {part} {/* Add a line break before the number */}
          </React.Fragment>
        );
      }

      // Return other text as is
      return part;
    });
  };

  return (
    <div className="mb-[20px]">
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5">
        <form onSubmit={handleSubmit}>
          {/* Conditional rendering of label text based on isQueryDisabled */}
          <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
            <h2 id="Office-Information" className="font-playfair text-[28px]  text-darkblue font-medium leading-8">
              {isQueryDisabled ? "Query Disabled for HB1" : "Enter your query about the bill:"}
            </h2>
          </div>
          <div className="flex  flex-col gap-2 mb-[20px] ">
            <div className="flex lg:flex-row flex-col items-center justify-between gap-4 ">
              <input
                type="text"
                className="text-black text-base font-normal border border-[#E5E7EB] w-full h-[46px] p-3.5 bg-white focus:outline-none placeholder:text-black"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={isQueryDisabled ? "" : "Type your query here..."} // Optionally clear the placeholder if disabled
                disabled={isQueryDisabled}
              />
              <button
                type="submit"
                className="bg-[#ECEFF4] lg:w-[130px] w-full h-[46px] p-4 py-3.5 text-center text-black text-base font-normal leading-6 whitespace-nowrap flex items-center justify-center transition-all duration-300 hover:bg-lightblue-hover hover:text-white"
                disabled={isLoading || isQueryDisabled}
              >
                Submit Query
              </button>
            </div>
          </div>
        </form>

        {isQueryDisabled && <p className="queryDisabledMessage">Query functionality is disabled for this bill.</p>}

        <div className="border border-[#E5E7EB] bg-white w-full p-5" id="queryLog">
          <div className="mb-5 flex items-center justify-between">
            <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
              Query Log:
            </h2>
            <div className="flex items-center gap-[15px]">
              <button
                className="py-3 px-12 bg-[#83B2FA] text-base font-normal text-white transition-all duration-300 hover:bg-lightblue-hover"
                onClick={PrintQueryLog}
                disabled={queries.length === 0}
              >
                Print
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 mb-[30px]">
            {recommendedQueries["precision"].map((rq, index) => (
              <button
                className="py-2.5 bg-[#F9FAFB] border border-[#E5E7EB] px-1.5 w-full max-w-[186px] text-center text-sm font-normal text-[#0000F1] whitespace-nowrap"
                key={index}
                onClick={() => handleSelectRecommendedQuery(rq.query)}
              >
                {rq.label}
              </button>
            ))}
          </div>
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner loadingContext={"query"} color="#1DB0FF" size={150} />
            </div>
          ) : (
            <div className="">
              {queries.length === 0 ? (
                <>
                  <p className="noQuery">No queries submitted yet.</p>
                  <div className="notification">{notification}</div>
                </>
              ) : (
                queries &&
                queries
                  .slice()
                  .reverse()
                  .map((entry, index) => (
                    <div className="flex items-start gap-4 mb-[20px]" key={index}>
                      <div className="flex flex-col gap-[25px]">
                        <div className="flex items-start gap-[15px]">
                          <h6 className="font-playfair font-medium text-[22px] leading-[29px] text-black">Query: </h6>
                          <p className="text-lg font-normal leading-[30px] text-black">{entry.query}</p>
                        </div>
                        <div className="flex items-start gap-[15px]">
                          <h6 className="font-playfair font-medium text-[22px] leading-[29px] text-black">
                            Response:{" "}
                          </h6>
                          <p className="text-lg font-normal leading-[30px] text-black">
                            {renderResponseWithBold(entry.response)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2.5">
                        <button
                          className={`good ${activeButtons[index] === "up" ? "active" : ""}`}
                          onClick={() => handleFeedback(index, entry.query, entry.response, true)}
                        >
                          <ThumbsUpIcon />
                        </button>
                        <button
                          className={`bad ${activeButtons[index] === "down" ? "active" : ""}`}
                          onClick={() => handleFeedback(index, entry.query, entry.response, false)}
                        >
                          <ThumbsDownIcon />
                        </button>
                      </div>
                    </div>
                  ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillQueryForm;
