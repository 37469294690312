import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const SearchBar = (props) => {
  const { state } = useParams();
  const { lobby } = props;
  const nav = useNavigate();
  const [placeholder] = useState("Search by Lobbyists, Company, or Firm");
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const inputRef = useRef();
  const data = lobby.lobby.data1; // Lobbyists
  const data2 = lobby.lobby.data2; // Companies

  useEffect(() => {
    inputRef.current.focus();
  }, [lobby, nav]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const searchParts = searchWord.toLowerCase().split(" ");

    const filterBySearchParts = (value, fields) => {
      return searchParts.every((part) => fields.some((field) => value[field]?.toLowerCase().includes(part)));
    };

    // Update filter function for names
    const filterBySearchPartsForNames = (value) => {
      const fullName = `${value.LobbyistFirstName} ${value.LobbyistLastName}`.toLowerCase();
      return searchParts.every((part) => fullName.includes(part));
    };

    const lobbyistsFilter = data.filter(filterBySearchPartsForNames);
    const clientsFilter = data2.filter((value) => filterBySearchParts(value, ["RepresenationName"]));

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData([
        ...lobbyistsFilter.map((value) => ({ ...value, dataset: "data" })),
        ...clientsFilter.map((value) => ({ ...value, dataset: "data2" })),
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && filteredData.length > 0) {
      const firstItem = filteredData[0];
      let href;
      switch (firstItem.dataset) {
        case "data":
          href = `/lobbyist/${firstItem.FilerID}`;
          break;
        case "data2":
          href = `/company/${firstItem.CompanyID}`;
          break;
        default:
          href = "#"; // Or some default URL
      }
      setWordEntered("");
      setFilteredData([]);
      nav(`/${state}${href}`);
    }
  };

  return (
    <div className="relative w-[70%] flex justify-end gap-4">
      <input
        type="text"
        className="border w-full border-[#E5E7EB] bg-white text-black text-base font-normal block py-2 px-3.5 focus:outline-none placeholder:text-black/30"
        ref={inputRef}
        placeholder={placeholder}
        value={wordEntered}
        onChange={handleFilter}
        onKeyDown={handleKeyDown}
      />
      <div className="flex items-center">
        <button className="btn btn-enabled">Search</button>
      </div>
      {filteredData.length !== 0 && (
        <div className="absolute top-[50px] left-0 h-auto max-h-[320px] overflow-y-auto w-[100%] bg-white border border-[#E5E7EB] px-5 py-2">
          {/* Lobbyists Section */}
          {renderSection(filteredData, "data", "Lobbyist", `/${state}/lobbyist`)}
          {/* Companies Section */}
          {renderSection(filteredData, "data2", "Company", `/${state}/company`)}
        </div>
      )}
    </div>
  );
};

// Function to render each section
const renderSection = (data, dataset, label, pathPrefix) => {
  const filteredSection = data.filter((item) => item.dataset === dataset);
  if (filteredSection.length === 0) return null;

  // Set the limit for the number of items to show based on the dataset
  const limit = dataset === "data3" ? 1 : 4; // Only 1 for firms (data3), 4 for others

  return (
    <>
      <div className="mt-3">
        <span className="border-b border-[#E5E7EB] mb-5 pb-2.5 flex items-center justify-between font-playfair text-md text-darkblue font-medium">
          {label}
        </span>
      </div>
      {filteredSection.slice(0, limit).map((item, index) => (
        <Link key={index} className="" to={`${pathPrefix}/${item.FilerID || item.CompanyID}`}>
          <p className="hover:bg-gray-100">
            {item.LobbyistFirstName ? `${item.LobbyistFirstName} ${item.LobbyistLastName}` : item.RepresenationName}
          </p>
        </Link>
      ))}
    </>
  );
};

export default SearchBar;
