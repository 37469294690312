import React from "react";
import { IoClose } from "react-icons/io5";

const WordPopup = ({ onClose, onGoToTimeStamp, handleSelectStart, handleSelectEnd }) => {
  return (
    <div className="bg-white shadow-lg flex flex-col text-base text-gray-500 p-4 gap-2 w-fit h-fit relative">
      <button className="absolute right-1 top-1 text-gray-400 rounded-full hover:bg-gray-50" onClick={onClose}>
        <IoClose className="w-4 h-4" />
      </button>
      <span className="cursor-pointer hover:bg-gray-50 px-2 rounded-sm" onClick={onGoToTimeStamp}>
        Go to video timestamp
      </span>
      <span className="cursor-pointer hover:bg-gray-50 px-2 rounded-sm" onClick={handleSelectStart}>
        Select start time
      </span>
      <span className="cursor-pointer hover:bg-gray-50 px-2 rounded-sm" onClick={handleSelectEnd}>
        Select end time
      </span>
    </div>
  );
};

export default WordPopup;
