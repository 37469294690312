import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/images/logo.png";
import menu from "../../assets/images/menu.png";

const Navbar = ({ openLogin, setOpenLogin }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const menuRef = useRef(null);

  const handleScroll = () => {
    const scroll = window.scrollY;
    if (scroll > 0) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      if (isOpen) {
        menuRef.current.style.height = menuRef.current.scrollHeight + "px";
      } else {
        menuRef.current.style.height = "0px";
      }
    } else {
      menuRef.current.style.height = "auto";
    }
  }, [isOpen]);

  return (
    <nav
      className={` py-5 px-0 backdrop-blur-md lg:transition-all lg:duration-500 fixed top-0 left-0 w-full z-[1030] items-center shadow-md lg:shadow-none [&.is-sticky]:bg-darkblue group [&.is-sticky]:shadow-md ${
        navBackground ? "bg-blue-950" : "bg-white/10"
      }`}
      id="navbar"
    >
      <div className="container mx-auto lg:px-10 px-8">
        <div className="flex lg:flex-nowrap flex-wrap items-center">
          <a className="flex items-center" href="#">
            <img src={logo} className="w-52 flex" alt="Logo" />
          </a>
          <div className="lg:hidden flex items-center ms-auto px-2.5">
            <button
              className="hs-collapse-toggle"
              type="button"
              id="hs-unstyled-collapse"
              data-collapse="#navbarCollapse"
            >
              <img src={menu} alt="Menu" />
            </button>
          </div>
          <div
            className="navigation lg:basis-auto basis-full grow items-center justify-center lg:flex mx-auto overflow-hidden mt-6 lg:mt-0 nav-light hidden"
            id="navbarCollapse"
            ref={menuRef}
          >
            <ul
              className="navbar-nav flex-col lg:flex-row gap-y-2 flex lg:items-center justify-center"
              id="navbar-navlist"
            >
              <li className="nav-item hover:text-white text-white/70 [&.active]:!text-lightblue lg:mx-6 mx-1 transition-all duration-500">
                <a
                  className="inline-flex items-center text-sm lg:text-base font-medium capitalize transition-all duration-500"
                  href="#home"
                >
                  Home
                </a>
              </li>
              <li className="nav-item [&.active]:!text-lightblue text-white/70 hover:text-white xl:mx-6 mx-1.5 transition-all duration-500">
                <a
                  className="inline-flex items-center text-sm lg:text-base font-medium capitalize transition-all duration-500"
                  href="#service"
                >
                  Services
                </a>
              </li>
              <li className="nav-item [&.active]:!text-lightblue text-white/70 hover:text-white xl:mx-6 mx-1.5 transition-all duration-500">
                <a
                  className="inline-flex items-center text-sm lg:text-base font-medium capitalize transition-all duration-500"
                  href="#about"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item [&.active]:!text-lightblue text-white/70 hover:text-white xl:mx-6 mx-1.5 transition-all duration-500">
                <a
                  className="inline-flex items-center text-sm lg:text-base font-medium capitalize transition-all duration-500"
                  href="#why-choose"
                >
                  Why Choose Us
                </a>
              </li>
              <li className="nav-item [&.active]:!text-lightblue text-white/70 hover:text-white xl:mx-6 mx-1.5 transition-all duration-500">
                <a
                  className="inline-flex items-center text-sm lg:text-base font-medium capitalize transition-all duration-500"
                  href="#testimonial"
                >
                  Testimonials
                </a>
              </li>
            </ul>
          </div>
          <div className="ms-auto shrink hidden lg:inline-flex gap-2">
            <button
              className="bg-lightblue rounded-[50px] py-3 px-6 min-w-32 text-center flex items-center justify-center transition-all duration-500 lg:text-base text-sm text-white font-medium hover:bg-lightblue-hover"
              onClick={() => setOpenLogin(true)}
            >
              <span className="hidden sm:block">Login</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
