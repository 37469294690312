import React, { useEffect, useState } from "react";
// Utils
import { getBaseURL } from "../../utils";
// Spinner
import { BounceLoader } from "react-spinners";
// Icons
import { IoCloseOutline } from "react-icons/io5";
import { HiCheckCircle } from "react-icons/hi";
import { IoIosWarning } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const SharablePopup = ({ handleClose, error, isLoading, uuid }) => {
  const [textToCopy, setTextToCopy] = useState("");
  const { state } = useParams();

  useEffect(() => {
    setTextToCopy(`${getBaseURL()}/${state}/videos/share/${uuid}`);
  }, [isLoading]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        alert("URL copied in the clipboard");
      },
      () => {
        alert("Error");
      }
    );
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50">
        {isLoading ? (
          <BounceLoader color="#1DB0FF" size={150} />
        ) : (
          <>
            <div className="w-[700px] bg-white p-2 justify-center flex">
              <div className="w-full h-full flex flex-col justify-center items-center m-6 gap-4">
                {error ? (
                  <>
                    <p className="text-center text-3xl font-medium">Something went wrong!</p>
                    <IoIosWarning className="w-[100px] h-[100px] text-orange-500" />
                  </>
                ) : (
                  <>
                    <span className="text-center text-2xl font-medium flex items-center gap-3">
                      You've created a new clip! <HiCheckCircle className="w-[50px] h-[50px] text-green-500" />
                    </span>
                    <p className="text-center text-md font-medium">
                      Copy the sharable link below and paste in a message, email, or on your social media platform of
                      choice.
                    </p>
                    <div className="p-4 flex items-center">
                      <span id="base-url" className="text-sm font-semibold p-2 border border-gray-400">
                        {textToCopy}
                      </span>
                      <button onClick={copyToClipboard} className="ml-4 p-2 text-gray-500 hover:bg-gray-200 ">
                        <FaRegCopy className="w-6 h-6" />
                      </button>
                    </div>
                  </>
                )}
              </div>
              <button
                className=" bg-transparent border-none h-fit text-3xl mr-1 rounded-full cursor-pointer hover:bg-gray-100"
                onClick={handleClose}
              >
                <IoCloseOutline className="w-7 h-7" />
              </button>
            </div>
          </>
        )}
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default SharablePopup;
