import axios from "axios";

const getVideoInfo = async (videoId) => {
  try {
    const response = await axios.get(`/api/video/${videoId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const videoService = {
  getVideoInfo,
};

export default videoService;
