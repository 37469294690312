import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// components
import Navbar from "../components/Structures/Navbar";
import Footer from "../components/Structures/Footer";
import Modal from "../components/Structures/Modal";
import Login from "../components/Login";
import Slider from "react-slick";
// images
import arrowDown from "../assets/images/arrow-img.png";
import heroBg from "../assets/images/hero-background.png";
import aboutImage from "../assets/images/about-img.png";
import userImage from "../assets/images/user-img.png";
// styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const services = [
  {
    title: "Search",
    description: "Find the bills that affect you with powerful manual and automatic searches such as:",
    features: ["Keyword", "Legal citation", "Artificial Intelligence", "Subject", "Author", "Progress", "Companion"],
    iconPath: [
      {
        d: "M31.5 31.5L37.125 37.125",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinejoin: "round",
      },
      {
        d: "M36.9749 41.6816C35.675 40.3818 35.675 38.2745 36.9749 36.9749C38.2745 35.675 40.3818 35.675 41.6816 36.9749L48.525 43.8182C49.8248 45.1181 49.8248 47.2254 48.525 48.525C47.2254 49.8248 45.1181 49.8248 43.8182 48.525L36.9749 41.6816Z",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinecap: "round",
      },
      {
        d: "M36 20.25C36 11.5515 28.9485 4.5 20.25 4.5C11.5515 4.5 4.5 11.5515 4.5 20.25C4.5 28.9485 11.5515 36 20.25 36C28.9485 36 36 28.9485 36 20.25Z",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinejoin: "round",
      },
    ],
  },
  {
    title: "Track",
    description: "Organize your legislation in a multitude of ways to make retrieval easy such as:",
    features: ["By Client", "By Position", "By Priority", "By Subject", "By Analyst", "By Cell Phone"],
    iconPath: [
      {
        d: "M42.1166 24.109C41.6831 24.5203 41.1035 24.75 40.5002 24.75C39.897 24.75 39.3174 24.5203 38.8838 24.109C34.9126 20.3213 29.5909 16.09 32.1863 9.94691C33.5894 6.62539 36.9578 4.5 40.5002 4.5C44.0426 4.5 47.4109 6.62539 48.8142 9.94691C51.4062 16.0823 46.0976 20.3344 42.1166 24.109Z",
        stroke: "#182158",
        strokeWidth: "3",
      },
      {
        d: "M40.5 13.5H40.521",
        stroke: "#182158",
        strokeWidth: "3.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M11.25 49.5C14.9779 49.5 18 46.4779 18 42.75C18 39.0221 14.9779 36 11.25 36C7.52208 36 4.5 39.0221 4.5 42.75C4.5 46.4779 7.52208 49.5 11.25 49.5Z",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M24.75 15.75H21.375C17.0258 15.75 13.5 18.7721 13.5 22.5C13.5 26.228 17.0258 29.25 21.375 29.25H28.125C32.4743 29.25 36 32.272 36 36C36 39.728 32.4743 42.75 28.125 42.75H24.75",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
    ],
  },
  {
    title: "Report",
    description: "Professional reports to manage your workload, engage your clients and inform colleagues.",
    features: ["Bill Status", "Meetings", "Calendars", "Contributions", "Research", "Analytics"],
    iconPath: [
      {
        d: "M14.625 39.375V32.625M25.875 39.375V19.125M37.125 39.375V30.375",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinecap: "round",
      },
      {
        d: "M48.375 12.375C48.375 16.1029 45.353 19.125 41.625 19.125C37.897 19.125 34.875 16.1029 34.875 12.375C34.875 8.64709 37.897 5.625 41.625 5.625C45.353 5.625 48.375 8.64709 48.375 12.375Z",
        stroke: "#182158",
        strokeWidth: "3",
      },
      {
        d: "M48.3649 24.75C48.3649 24.75 48.375 25.5139 48.375 27C48.375 37.0764 48.375 42.1144 45.2448 45.2448C42.1144 48.375 37.0762 48.375 27 48.375C16.9237 48.375 11.8856 48.375 8.75529 45.2448C5.625 42.1144 5.625 37.0764 5.625 27C5.625 16.9238 5.625 11.8857 8.75529 8.75536C11.8856 5.62507 16.9237 5.62507 27 5.62507L29.25 5.625",
        stroke: "#182158",
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
    ],
  },
];

const servicesOffered = [
  {
    number: "01",
    title: "Atlas",
    description:
      "ATLAS benefits any organization that wants to have committee hearings, calendars, tracking reports, custom reports and NIGHTWriter reports...",
    link: "#",
  },
  {
    number: "02",
    title: "Eclips",
    description:
      "TELICON E-Clips is the power tool that delivers the public perception and media component to a legislative campaign...",
    link: "#",
  },
  {
    number: "03",
    title: "NightWriter",
    description:
      "NIGHTWriter profiles the answer to that question in an automatic report that is emailed and can even be printed automatically. You choose the options...",
    link: "#",
  },
  {
    number: "04",
    title: "TxAlert",
    description:
      "TxALERT keeps you informed where you are when you can't watch both the House and Senate, twenty committee rooms, the governor's office...",
    link: "#",
  },
  {
    number: "05",
    title: "Telicon AI",
    description:
      "TELICON AI One of the most progressive new tools in legislative intelligence is the use of artificial intelligence (AI) to locate legislation...",
    link: "#",
  },
  {
    number: "06",
    title: "Telicon Mobile",
    description:
      "TELICON Mobile addresses the needs of the legislative professional who is away from their computer during the day but still needs the vital information...",
    link: "#",
  },
  {
    number: "07",
    title: "Telicon Stats",
    description:
      "TELICON Stats is a suite of real-time statistical analysis tools to help you make intelligent decisions regarding your legislative agenda...",
    link: "#",
  },
  {
    number: "08",
    title: "Telicon Multi-Stats Access",
    description:
      "Many pieces of legislation do not originate in Texas. There are numerous national organizations and associations that craft model legislation...",
    link: "#",
  },
];

const Home = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const navigate = useNavigate();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    focusOnSelect: true,
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      console.log("HERE IS ");
      navigate(`/${user.state}/search/${user.searchTerm || ""}`);
    }
  }, []);

  return (
    <>
      <div className="font-inter">
        <Navbar openLogin={openLogin} setOpenLogin={setOpenLogin} />
        <section
          id="home"
          className="lg:h-screen relative pt-32 lg:pb-32 pb-20 overflow-x-hidden bg-no-repeat bg-cover before:absolute before:w-full lg:before:h-screen before:h-full before:bg-[linear-gradient(180deg,rgba(0,0,0,0.50)_0%,rgba(0,0,0,0.00)_19.44%),linear-gradient(90deg,rgba(0,0,0,0.80)_0%,rgba(0,0,0,0.30)_100%)] before:top-0 before:left-0 flex items-center"
          style={{ backgroundImage: `url(${heroBg})` }}
        >
          <div className="left-arrow absolute 2xl:left-64 xl:!left-36 lg:bottom-12 2xl:flex justify-center flex-col items-center hidden">
            <img src={arrowDown} alt="Arrow" />
            <span className="text-base text-white font-medium leading-8 tracking-widest mt-5">Discovery</span>
          </div>
          <div className="container mx-auto lg:px-10 px-8">
            <div className="xl:max-w-[890px] lg:max-w-max xl:ml-52 lg:ml-0 relative">
              <h1 className="font-playfair lg:text-[80px] md:text-[60px] text-[45px] text-white font-normal capitalize text-left lg:leading-[100px] md:leading-[80px] leading-[60px] tracking-wide mb-8">
                Innovative legislative information for Texas
              </h1>
              <p className="text-base text-white font-normal leading-8 mb-12 lg:max-w-[800px] max-w-max">
                TELICON is an unmatched combination of comprehensive data, analytical tools, real-time entry,
                broad-spectrum delivery, and experience that provides the most innovative lobbying tool to the
                legislative professionals of Texas.
              </p>
              <a
                href="#"
                className="bg-lightblue rounded-[50px] py-3 px-6 min-w-32 text-center flex items-center justify-center transition-all duration-500 lg:text-base text-sm text-white font-medium hover:bg-lightblue-hover w-56 h-14"
              >
                Learn More!
              </a>
            </div>
          </div>
        </section>

        <section id="service" className="py-[50px] bg-[#ECEFF4]">
          <div className="container mx-auto lg:px-10 px-8">
            <div className="relative text-center mb-[75px]">
              <span className="text-darkblue lg:text-xl text-lg font-bold tracking-wider uppercase">Our Services</span>
              <h2 className="text-black font-playfair lg:text-[55px] md:text-[42px] text-[34px] font-medium capitalize lg:leading-[75px] leading-[50px]">
                Our Featured Services
              </h2>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-0 gap-4">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="bg-[#FDFDFD] lg:p-10 p-5 transition-all duration-500 relative lg:min-h-[600px] min-h-max hover:bg-darkblue group"
                >
                  <div className="absolute right-10 top-10">
                    <button className="h-[85px] w-[85px] rounded-full flex items-center justify-center border border-white/70 transition-all duration-500 bg-transparent group ">
                      <svg
                        className="group-hover:hover:rotate-45 transition-all duration-500"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.3806 27.5376L32.3806 15.4405L20.2835 15.4405"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4404 32.3804L32.2109 15.6099"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="lg:w-[100px] lg:h-[100px] w-[80px] h-[80px] flex items-center justify-center bg-[#FFECD2] mb-8 service-icon-box group-hover:bg-[#C0DAFF]">
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                      {service.iconPath.map((path, i) => (
                        <path
                          key={i}
                          d={path.d}
                          stroke={path.stroke}
                          strokeWidth={path.strokeWidth}
                          strokeLinecap={path.strokeLinecap}
                          strokeLinejoin={path.strokeLinejoin}
                        />
                      ))}
                    </svg>
                  </div>
                  <h4 className="text-[32px] text-black leading-8 mb-5 font-medium group-hover:text-white">
                    {service.title}
                  </h4>
                  <p className="text-black/70 text-base font-normal leading-8 mb-5 group-hover:text-white">
                    {service.description}
                  </p>
                  <ul className="mb-0 pl-5">
                    {service.features.map((feature, i) => (
                      <li
                        key={i}
                        className="list-disc lg:text-lg text-base !leading-9 text-black font-normal group-hover:text-white"
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="about" className="py-[50px]">
          <div className="container mx-auto lg:px-10 px-8">
            <div className="flex justify-between gap-16 items-center xl:flex-row flex-col">
              <div className="block relative xl:w-[55%]">
                <div className="absolute bottom-1 -left-[75px] -z-10 lg:block hidden">
                  <svg width="136" height="162" viewBox="0 0 136 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      {Array.from({ length: 14 }, (_, row) =>
                        Array.from({ length: 6 }, (_, col) => (
                          <circle
                            key={`circle-${row}-${col}`}
                            cx={3 + col * 26}
                            cy={3 + row * 26}
                            r="3"
                            fill="#8493EF"
                          />
                        ))
                      )}
                    </g>
                  </svg>
                </div>
                <img src={aboutImage} alt="about" />
              </div>
              <div className="relative xl:w-[45%]">
                <div className="relative xl:text-left text-center">
                  <span className="text-darkblue lg:text-xl text-lg font-bold tracking-wider uppercase mb-4 block">
                    About Us
                  </span>
                  <h2 className="text-black font-playfair lg:text-[55px] md:text-[42px] text-[34px] font-medium capitalize lg:leading-[75px] leading-[50px] mb-6">
                    Who We Are
                  </h2>
                  <p className="text-black/70 text-lg font-normal leading-9">
                    TELICON is an unmatched combination of comprehensive data, analytical tools, real-time entry,
                    broad-spectrum delivery, and experience that provides the most innovative lobbying tool to the
                    legislative professionals of Texas.
                  </p>
                </div>
                <div className="mt-[50px]">
                  <div className="flex lg:flex-row flex-col gap-8 mb-[50px]">
                    <div className="w-[70px] h-[70px] rounded-full flex justify-center items-center bg-darkblue">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M25.2711 23.5745C24.6669 21.7763 22.2588 20.6507 20.5419 19.8962C19.8693 19.6016 18.0072 19.1018 17.7834 18.2549C17.7033 17.9498 17.7141 17.6621 17.7798 17.3864C17.6763 17.4062 17.5713 17.4179 17.4627 17.4179H16.344C15.4509 17.4179 14.7252 16.6913 14.7252 15.7988C14.7252 14.9069 15.4512 14.1818 16.344 14.1818H17.4627C17.8323 14.1818 18.1827 14.3069 18.4653 14.5301C18.8796 14.4749 19.2792 14.3864 19.6518 14.2703C20.1411 13.2452 20.5227 12.0194 20.6082 10.9643C20.9733 6.44993 18.2058 3.80873 14.2377 4.26533C11.3526 4.59743 9.62913 6.74873 9.44283 9.51833C9.25443 12.3413 10.3011 14.4263 11.4129 15.9557C11.8998 16.6244 12.4113 17.0543 12.3327 17.8601C12.2415 18.8129 11.2227 19.0784 10.494 19.3712C9.63063 19.718 8.70063 20.2442 8.26143 20.4875C6.74853 21.323 5.08803 22.3292 4.71483 23.7056C3.88833 26.756 6.67953 27.68 8.98383 28.1066C10.9614 28.4714 13.1913 28.5002 15.0255 28.5002C18.3432 28.5002 24.309 28.3673 25.2711 25.8734C25.5447 25.1657 25.4274 24.038 25.2711 23.5745Z"
                          fill="white"
                        ></path>
                        <path
                          d="M18.171 15.3429C18.0192 15.1116 17.7597 14.958 17.4636 14.958H16.3449C15.8781 14.958 15.5016 15.3351 15.5016 15.7995C15.5016 16.2657 15.8781 16.6434 16.3449 16.6434H17.4636C17.7903 16.6434 18.0675 16.4571 18.2073 16.1877C19.767 16.065 21.1236 15.5886 22.0758 14.8851C22.2945 15.0261 22.5531 15.1086 22.8321 15.1086H22.9023C23.6799 15.1086 24.3087 14.4792 24.3087 13.7007V10.8903C24.3087 10.3305 23.9805 9.8475 23.5071 9.6231C23.3007 5.1102 19.5648 1.5 15.0012 1.5C10.4376 1.5 6.70106 5.1102 6.49556 9.6231C6.02126 9.8478 5.69336 10.3305 5.69336 10.8903V13.7007C5.69336 14.4792 6.32276 15.1086 7.09856 15.1086H7.16966C7.94606 15.1086 8.57576 14.4792 8.57576 13.7007V10.8903C8.57576 10.3386 8.25686 9.8628 7.79336 9.6327C7.99406 5.8302 11.1495 2.7993 15.0012 2.7993C18.8514 2.7993 22.0083 5.8302 22.2081 9.6327C21.7452 9.8631 21.4266 10.3386 21.4266 10.8903V13.7007C21.4266 13.8873 21.4629 14.061 21.5268 14.2233C20.7072 14.8098 19.5141 15.2277 18.171 15.3429Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                    <div className="block flex-1">
                      <h5 className="text-black font-playfair lg:text-[25px] text-xl font-medium leading-7 mb-4">
                        Free Consultations
                      </h5>
                      <p className="lg:text-lg text-base text-black/70 font-normal !leading-9">
                        Lorem ipsum dolor sit amet consectetur. Pellentesque condimentum urna enim lacus bibendum.
                        Vivamus pretium.
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col gap-8 mb-[50px]">
                    <div className="w-[70px] h-[70px] rounded-full flex justify-center items-center bg-darkblue">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M29.5875 11.5259L25.8532 7.79159L24.8378 2.66334C24.749 2.19146 24.5187 1.75896 24.1789 1.42936C23.5782 0.83571 22.7185 0.544586 21.8532 0.657837L16.725 1.67271L12.9907 4.90271L1.8832 15.9796C1.2782 16.5846 0.9282 17.3668 0.89245 18.1933L0.759949 21.0068C0.717624 21.9013 1.04382 22.7765 1.65495 23.4062L6.1932 27.9446C6.8282 28.5796 7.67395 28.9171 8.55895 28.8868L11.3725 28.7543C12.1982 28.7173 12.9803 28.3673 13.5845 27.7621L24.696 16.6546L27.9437 12.9446L28.9587 7.81634C29.072 6.95109 28.7808 6.09134 28.1865 5.49021C27.8569 5.15046 27.4244 4.92009 26.9525 4.83134L21.8243 3.81521L19.349 1.32409C20.7782 1.05671 22.2253 1.21596 23.5378 1.79984C24.519 2.26634 25.3275 3.01296 25.8375 3.94121L27.0125 7.30934L30 11.1465L29.5875 11.5259ZM4.75995 20.8655L3.83745 19.943L4.33195 17.0621L8.3932 21.1233L5.50495 21.6368L4.75995 20.8655Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                    <div className="block flex-1">
                      <h5 className="text-black font-playfair lg:text-[25px] text-xl font-medium leading-7 mb-4">
                        Expert Lawyers
                      </h5>
                      <p className="lg:text-lg text-base text-black/70 font-normal !leading-9">
                        Lorem ipsum dolor sit amet consectetur. Pellentesque condimentum urna enim lacus bibendum.
                        Vivamus pretium.
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col gap-8 mb-[50px]">
                    <div className="w-[70px] h-[70px] rounded-full flex justify-center items-center bg-darkblue">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M29.5875 11.5259L25.8532 7.79159L24.8378 2.66334C24.749 2.19146 24.5187 1.75896 24.1789 1.42936C23.5782 0.83571 22.7185 0.544586 21.8532 0.657837L16.725 1.67271L12.9907 4.90271L1.8832 15.9796C1.2782 16.5846 0.9282 17.3668 0.89245 18.1933L0.759949 21.0068C0.717624 21.9013 1.04382 22.7765 1.65495 23.4062L6.1932 27.9446C6.8282 28.5796 7.67395 28.9171 8.55895 28.8868L11.3725 28.7543C12.1982 28.7173 12.9803 28.3673 13.5845 27.7621L24.696 16.6546L27.9437 12.9446L28.9587 7.81634C29.072 6.95109 28.7808 6.09134 28.1865 5.49021C27.8569 5.15046 27.4244 4.92009 26.9525 4.83134L21.8243 3.81521L19.349 1.32409C20.7782 1.05671 22.2253 1.21596 23.5378 1.79984C24.519 2.26634 25.3275 3.01296 25.8375 3.94121L27.0125 7.30934L30 11.1465L29.5875 11.5259ZM4.75995 20.8655L3.83745 19.943L4.33195 17.0621L8.3932 21.1233L5.50495 21.6368L4.75995 20.8655Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                    <div className="block flex-1">
                      <h5 className="text-black font-playfair lg:text-[25px] text-xl font-medium leading-7 mb-4">
                        Affordable Price
                      </h5>
                      <p className="lg:text-lg text-base text-black/70 font-normal !leading-9">
                        Lorem ipsum dolor sit amet consectetur. Pellentesque condimentum urna enim lacus bibendum.
                        Vivamus pretium.
                      </p>
                    </div>
                  </div>
                  <a
                    href="about.html"
                    className="text-white bg-darkblue hover:bg-darkblue/80 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-lg px-6 py-4 text-center inline-flex items-center"
                  >
                    Learn More
                    <svg
                      className="w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 2.293a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L14 5.414V17a1 1 0 11-2 0V5.414l-2.707 2.707a1 1 0 01-1.414-1.414l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="why-choose" className="py-[50px]">
          <div className="container mx-auto lg:px-10 px-8">
            <div className="relative text-center mb-[75px]">
              <span className="text-darkblue lg:text-xl text-lg font-bold tracking-wider uppercase">Key Stats</span>
              <h2 className="text-black font-playfair lg:text-[55px] md:text-[42px] text-[34px] font-medium capitalize lg:leading-[75px] leading-[50px]">
                Why Choose Us?
              </h2>
            </div>
            <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 lg:gap-11 gap-8">
              {servicesOffered.map((service, index) => (
                <div key={index} className="p-6 border border-[#E5E7EB] bg-[#F9FAFB] min-h-[400px]">
                  <div className="bg-gold lg:w-[84px] lg:h-[84px] w-[70px] h-[70px] text-[30px] text-white font-medium flex justify-center items-center rounded-full mb-8">
                    {service.number}
                  </div>
                  <h5 className="font-playfair text-black font-medium text-[25px] leading-8 mb-3">{service.title}</h5>
                  <p className="lg:text-base text-sm text-black/70 font-normal !leading-7 mb-4">
                    {service.description}
                  </p>
                  <a href={service.link} className="text-gold border-b border-gold text-base font-bold leading-7">
                    Learn More
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="testimonial" className="py-[50px] mb-10">
          <div className="container mx-auto lg:px-10 px-8">
            <div className="relative text-center mb-[50px]">
              <span className="text-darkblue lg:text-xl text-lg font-bold tracking-wider uppercase">
                Our Testimonial
              </span>
              <h2 className="text-black font-playfair lg:text-[55px] md:text-[42px] text-[34px] font-medium capitalize lg:leading-[75px] leading-[50px]">
                What Our Customers have to say
              </h2>
            </div>
            <div className="block lg:mx-20">
              <div className="testimonial-slider">
                <Slider {...settings}>
                  <div className="bg-[#F4F1EC] lg:p-10 p-5">
                    <div className="flex items-center gap-3 mb-8">
                      <div className="block">
                        <img src={userImage} alt="user-img" />
                      </div>
                      <div className="block">
                        <h5 className="text-black text-lg font-semibold leading-7">Cameron Williamson</h5>
                        <span className="text-gold text-sm font-semibold leading-6">LLB (Bachelor of Laws) </span>
                      </div>
                    </div>
                    <p className="text-black/50 text-base font-normal leading-7 tracking-wide mb-7">
                      Lorem ipsum dolor sit amet consectetur. Tellus nisl vehicula pretium etiam sed ligula nisl.
                      Viverra commodo sagittis quam mauris. Facilisis nulla nisl fusce ridiculus molestie suspendisse
                      convallis praesent ullamcorper. Eleifend purus commodo porta a suspendisse. Integer viverra
                      dignissim felis netus. Aliquam phasellus.
                    </p>
                    <div className="flex items-center">
                      <ul className="flex items-center gap-1 border-r border-[#D1D5DB] mr-3 pr-3">
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                      </ul>
                      <span className="text-base text-[#D7AF78] font-semibold leading-7">5.0</span>
                    </div>
                  </div>
                  <div className="bg-[#F4F1EC] lg:p-10 p-5">
                    <div className="flex items-center gap-3  mb-8">
                      <div className="block">
                        <img src={userImage} alt="user-img" />
                      </div>
                      <div className="block">
                        <h5 className="text-black text-lg font-semibold leading-7">Cameron Williamson</h5>
                        <span className="text-gold text-sm font-semibold leading-6">LLB (Bachelor of Laws) </span>
                      </div>
                    </div>
                    <p className="text-black/50 text-base font-normal leading-7 tracking-wide mb-7">
                      Lorem ipsum dolor sit amet consectetur. Tellus nisl vehicula pretium etiam sed ligula nisl.
                      Viverra commodo sagittis quam mauris. Facilisis nulla nisl fusce ridiculus molestie suspendisse
                      convallis praesent ullamcorper. Eleifend purus commodo porta a suspendisse. Integer viverra
                      dignissim felis netus. Aliquam phasellus.
                    </p>
                    <div className="flex items-center">
                      <ul className="flex items-center gap-1 border-r border-[#D1D5DB] mr-3 pr-3">
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                      </ul>
                      <span className="text-base text-[#D7AF78] font-semibold leading-7">5.0</span>
                    </div>
                  </div>
                  <div className="bg-[#F4F1EC] lg:p-10 p-5">
                    <div className="flex items-center gap-3 mb-8">
                      <div className="block">
                        <img src={userImage} alt="user-img" />
                      </div>
                      <div className="block">
                        <h5 className="text-black text-lg font-semibold leading-7">Cameron Williamson</h5>
                        <span className="text-gold text-sm font-semibold leading-6">LLB (Bachelor of Laws) </span>
                      </div>
                    </div>
                    <p className="text-black/50 text-base font-normal leading-7 tracking-wide mb-7">
                      Lorem ipsum dolor sit amet consectetur. Tellus nisl vehicula pretium etiam sed ligula nisl.
                      Viverra commodo sagittis quam mauris. Facilisis nulla nisl fusce ridiculus molestie suspendisse
                      convallis praesent ullamcorper. Eleifend purus commodo porta a suspendisse. Integer viverra
                      dignissim felis netus. Aliquam phasellus.
                    </p>
                    <div className="flex items-center">
                      <ul className="flex items-center gap-1 border-r border-[#D1D5DB] mr-3 pr-3">
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                        <li>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_738_1522)">
                              <path
                                d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                fill="#F59E0B"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_738_1522">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                      </ul>
                      <span className="text-base text-[#D7AF78] font-semibold leading-7">5.0</span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
      {openLogin && (
        <Modal onClose={() => setOpenLogin(false)} title="Login">
          <Login />
        </Modal>
      )}
    </>
  );
};

export default Home;
