import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import videoService from './videoService'

const initialState = {
  video: {},
  selectedText: '',
  filter: {
    date: null,
    chamber: '',
    committee: '',
    legislature: '',
  },
  timeStamp: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
}

export const getVideoInfo = createAsyncThunk(
  'video/getVideoInfo',
  async (videoId, thunkAPI) => {
    try {
      const response = await videoService.getVideoInfo(videoId)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState // Reset to initial state
    },
    setSelectedText: (state, action) => {
      state.selectedText = action.payload
    },
    clearSelectedText: (state) => {
      state.selectedText = ''
    },
    // Update filter state within the video slice
    setFilterDate: (state, action) => {
      state.filter.date = action.payload
    },
    setFilterChamber: (state, action) => {
      state.filter.chamber = action.payload
    },
    setFilterCommittee: (state, action) => {
      state.filter.committee = action.payload
    },
    setFilterLegislature: (state, action) => {
      state.filter.legislature = action.payload
    },
    clearAllFilters: (state) => {
      state.filter.date = null
      state.filter.committee = ''
      state.filter.chamber = ''
      state.filter.legislature = ''
    },
    setTimeStamp: (state, action) => {
      state.timeStamp = action.payload
    },
    clearTimeStamp: (state) => {
      state.timeStamp = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideoInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getVideoInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.video = action.payload
      })
      .addCase(getVideoInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export const {
  reset,
  setSelectedText,
  clearSelectedText,
  setFilterDate,
  setFilterChamber,
  setFilterCommittee,
  setFilterLegislature,
  clearAllFilters,
  setTimeStamp,
  clearTimeStamp,
} = videoSlice.actions
export default videoSlice.reducer
