import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

const initialState = {
  auth: storedUser.auth || null,
  searchTerm: storedUser.searchTerm || "",
  session: storedUser.session || "",
  state: storedUser.state || "",
  features: storedUser.features || [],
  tracks: storedUser.tracks || {},
  redirectUrl: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

export const getUser = createAsyncThunk("user/getUser", async (searchTerm, thunkAPI) => {
  try {
    const user = await userService.getUser(searchTerm);
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

export const login = createAsyncThunk("user/login", async (auth, thunkAPI) => {
  const { username, password } = auth;
  try {
    const res = await userService.login(username, password);
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.auth = null;
      state.features = [];
      state.session = "";
      state.searchTerm = "";
      state.state = "";
      state.tracks = {};
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = null;
    },
    clearQueries: (state) => {
      state.queries = [];
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    logOut: (state, action) => {
      state.auth = null;
      state.features = [];
      state.session = "";
      state.searchTerm = "";
      state.state = "";
      state.tracks = {};
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const { auth, features, searchTerm, state: userState, session, tracks } = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.auth = auth;
        state.features = features;
        state.searchTerm = searchTerm;
        state.session = session;
        state.state = userState;
        state.message = null;
        state.tracks = tracks;
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { auth, state: userState, features, session } = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.auth = auth;
        state.state = userState;
        state.features = features;
        state.session = session;
        state.message = null;
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset, clearQueries, setState, setRedirectUrl, logOut } = userSlice.actions;
export default userSlice.reducer;
