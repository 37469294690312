import React, { useEffect, useState } from "react";
// hooks
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import stateConfig from "../stateConfig";
// store
import { getSections, clearQuery } from "../features/bills/billSlice";
// components
import ReactMarkdown from "react-markdown";
import BillQueryForm from "../components/BillQueryForm";
import ThreeArrowsSVG from "../components/SVGs/ThreeArrowsSVG";
import Dropdown from "../components/Structures/Dropdown";
import MainLoader from "../components/Structures/MainLoader";

const BillPage = () => {
  const { billId, state } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const session = searchParams.get("session");

  const [isLoading, setIsLoading] = useState(true);
  const isQueryDisabled = billId === "HB1";
  const [version, setVersion] = useState(stateConfig[state.toLowerCase()]?.defaultVersion || "FIL");
  const [activeIndex, setActiveIndex] = useState(0);
  const [billTypes, setBillTypes] = useState([]);
  const [keyChanges, setKeyChanges] = useState("");
  const [showSectionSummary, setShowSectionSummary] = useState(true);

  const { bill, pdf, versions, billSummary } = useSelector((state) => state.bill);
  const { auth } = useSelector((state) => state.user);

  const billsText = bill.map((bil) => bil.metadata?.text).join("\n");

  useEffect(() => {
    const fetchBillData = async () => {
      try {
        if (billId && session) {
          const { sections, types } = await dispatch(getSections({ billId, session, version })).unwrap();

          setBillTypes(types);

          // Assuming sectionsData contains the bill text from which you extract key changes

          const allText = sections.map((section) => section.metadata?.text).join("\n");

          const extractedKeyChanges = extractKeyChanges(allText);
          setKeyChanges(extractedKeyChanges);

          // Set version to the first available if the current is not in the list
          if (versions.length > 0 && !versions.includes(version)) {
            setVersion(versions[0]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch bill data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (billId && session) {
      fetchBillData();
    }
  }, [billId, session, version, dispatch]);

  const handleSectionClick = (index) => {
    setActiveIndex(index); // Update active section index
  };

  const handleVersionComparison = () => {
    navigate(`../compare/${billId}`);
  };

  const extractKeyChanges = (text) => {
    const keyChangesStart = text.indexOf("Key Changes");
    const otherHighlightsStart = text.indexOf("Other Highlights");
    return keyChangesStart !== -1
      ? text.substring(keyChangesStart, otherHighlightsStart !== -1 ? otherHighlightsStart : undefined).trim()
      : "";
  };

  const formatTitle = (id) => {
    // Extract section and part numbers
    const sectionMatch = id.match(/S(\d+)/);
    const partMatch = id.match(/P(\d+)$/);
    const sectionNumber = sectionMatch ? sectionMatch[1] : "";
    const partNumber = partMatch ? ` Part ${partMatch[1]}` : "";
    if (sectionNumber === "0") {
      return { title: `Bill Intro ${partNumber}`, partNumber };
    } else {
      return { title: `Section ${sectionNumber}${partNumber}`, partNumber };
    }
  };

  const billSorter = (a, b) => {
    // Generalized regex to match the bill format including different prefixes, stages, sections, and parts

    const regex = new RegExp(`^(${billTypes.join("|")})(\\d+)(${versions.join("|")})?(S(\\d+))?(P(\\d+))?$`);

    const matchA = a.id.match(regex);
    const matchB = b.id.match(regex);

    if (!matchA || !matchB) return 0; // In case some id doesn't match the pattern

    // Compare prefixes
    if (matchA[1] !== matchB[1]) {
      return matchA[1].localeCompare(matchB[1]);
    }

    // Compare numeric part of the bill
    const numberA = parseInt(matchA[2], 10);
    const numberB = parseInt(matchB[2], 10);
    if (numberA !== numberB) return numberA - numberB;

    // Compare stages (FIL, ENG), assuming they might not always be present
    if (matchA[3] && matchB[3]) {
      if (matchA[3] !== matchB[3]) return matchA[3].localeCompare(matchB[3]);
    } else if (matchA[3] || matchB[3]) {
      return matchA[3] ? -1 : 1; // One has a stage, and the other doesn't
    }

    // Compare sections
    const sectionA = matchA[5] ? parseInt(matchA[5], 10) : -1; // Default to -1 if not present
    const sectionB = matchB[5] ? parseInt(matchB[5], 10) : -1;
    if (sectionA !== sectionB) return sectionA - sectionB;

    // Compare parts
    const partA = matchA[7] ? parseInt(matchA[7], 10) : -1; // Default to -1 if not present
    const partB = matchB[7] ? parseInt(matchB[7], 10) : -1;
    if (partA !== partB) return partA - partB;

    return 0; // Identical or no discernible order
  };

  const renderBillRecords = () => {
    const filteredBills = bill.filter((record) => record.id.includes(version));

    // Assuming bills is an array of objects where each object has an id property that needs sorting
    const billsArray = Object.values(filteredBills).sort(billSorter);

    return (
      billsArray?.length > 0 &&
      billsArray.map((record, index) => {
        const isOpen = index === activeIndex;
        const { title, partNumber } = formatTitle(record.id);
        const content = record.metadata && record.metadata.text ? record.metadata.text : "";

        let keyChanges = null;
        let otherHighlights = null;
        let overallImpact = null;

        if (content) {
          // Find the start indices of each subsection
          const keyChangesStart = content.indexOf("Key Changes");
          const otherHighlightsStart = content.indexOf("Other Highlights");
          const overallImpactStart = content.indexOf("Overall Impact");

          // Function to extract subsection content based on start and end indices
          const extractContent = (start, nextStart) =>
            start !== -1
              ? content
                  .substring(start, nextStart !== -1 ? nextStart : undefined)
                  .trim()
                  .replace(
                    /[<>[\]]|Overall Impact:\*\*|Key Changes:\*\*|Overall Impact:|Key Changes:|Overall Impact|Key Changes|\*\*$/g,
                    ""
                  )
              : null;

          // Extracting subsections based on identified indices
          keyChanges = extractContent(keyChangesStart, overallImpactStart);
          // otherHighlights = extractContent(otherHighlightsStart, overallImpactStart);
          overallImpact = extractContent(overallImpactStart, -1);
        }

        return (
          <li key={index} onClick={() => handleSectionClick(index)}>
            <h5 className="font-playfair text-2xl font-medium text-[#182158] mb-5">{title}</h5>
            {keyChanges && (
              <>
                <h6 className="font-playfair font-medium text-[22px] text-black mb-[15px]">Key Changes</h6>
                <p className="text-lg font-normal leading-[30px] text-black/70 mb-5">
                  <ReactMarkdown>{keyChanges}</ReactMarkdown>
                </p>
              </>
            )}

            {/* {otherHighlights && (
              <>
                <h6 className="font-playfair font-medium text-[22px] text-black mb-[15px]">Other Highlights</h6>
                <p className="text-lg font-normal leading-[30px] text-black/70 mb-5">{otherHighlights}</p>
              </>
            )} */}

            {overallImpact && (
              <>
                <h6 className="font-playfair font-medium text-[22px] text-black mb-[15px]">Overall Impact</h6>
                <p className="text-lg font-normal leading-[30px] text-black/70">
                  <ReactMarkdown>{overallImpact}</ReactMarkdown>
                </p>
              </>
            )}
          </li>
        );
      })
    );
  };

  return (
    <>
      {isLoading ? (
        <MainLoader />
      ) : (
        <>
          <div className="bg-[#F9FAFB] border border-[#E5E7EB] p-5 pb-[15px] w-full mb-[20px]">
            <div className="border-b border-[#E5E7EB] mb-5 pb-3.5 flex flex-col md:flex-row max-md:gap-4 items-center justify-between">
              <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
                Bill: {billId}
              </h2>
              <div className="flex flex-col sm:flex-row items-center gap-[15px]"></div>
            </div>
            <div className="flex items-center gap-[15px]">
              <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black">
                Version
                <ThreeArrowsSVG />
              </div>
              <div className="flex flex-col lg:flex-row w-full items-center gap-[15px]">
                <Dropdown
                  id="Numberofitem"
                  options={versions?.map((ver) => ver)}
                  title={versions.length > 0 ? "Versions" : "Loading versions..."}
                  value={version}
                  label={null}
                  onChange={(e) => {
                    setVersion(e.target.value);
                    dispatch(clearQuery({ auth }));
                  }}
                />
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    className="btn btn-enabled"
                    disabled={versions.length <= 1}
                    onClick={handleVersionComparison} // Assuming you have a function to handle version comparison
                  >
                    Compare Versions
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-[30px] w-full mb-[20px]">
            <div className="">
              <div className=" bg-[#F9FAFB] w-full h-full">
                <iframe
                  class="w-full h-[1220px]"
                  id="bill-pdf"
                  src={pdf} // Ensure your state or reducer updates the pdf link accordingly
                  title="PDF Viewer"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
            <div className="">
              <BillQueryForm
                billText={billsText}
                version={version}
                billId={billId}
                keyChanges={keyChanges}
                isQueryDisabled={isQueryDisabled}
              />
              <div className="bg-[#F9FAFB] border border-[#E5E7EB] p-5 pt-[15px]">
                <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
                  <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
                    AI {showSectionSummary ? "Section" : "Bill"} Summary
                  </h2>
                </div>
                <ul className={`tabs-nav max-w-[calc(50%-12px)] gap-4 flex !mb-5`}>
                  <li className={!showSectionSummary && "active"} onClick={() => setShowSectionSummary(false)}>
                    <div className="cursor-pointer">Bill Summary</div>
                  </li>
                  <li className={showSectionSummary && "active"} onClick={() => setShowSectionSummary(true)}>
                    <div className="cursor-pointer">Section Summary</div>
                  </li>
                </ul>
                {showSectionSummary ? (
                  <ul className="flex flex-col gap-[30px]">{renderBillRecords()}</ul>
                ) : (
                  <div>
                    <h6 className="font-playfair font-medium text-[22px] text-black mb-5">Key Changes</h6>
                    <p className="text-lg font-normal leading-[30px] text-black/70 mb-5">
                      <ReactMarkdown>{billSummary?.text.replace("Key Changes", "") || ""}</ReactMarkdown>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BillPage;
