import axios from "axios";

const getUser = async (searchTerm) => {
  try {
    const response = await axios.get(`/api/search/${searchTerm}`);
    if (typeof response?.data !== "object" || response?.data === null) {
      throw new Error("Invalid response format");
    }
    return response?.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(error.message);
  }
};

const login = async (username, password) => {
  try {
    const response = await axios.post(`/api/auth/login`, { username, password });
    return response?.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(error.message);
  }
};

const userService = { getUser, login };

export default userService;
