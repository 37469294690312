const recommendedQueries = {
  precision: [
    {
      label: "Ambiguous",
      query:
        "Are there any parts of this bill where the language could be misconstrued or loopholes could be created?",
    },
    {
      label: "Testimony For",
      query:
        "Give me verbatim testimony I can read to testify in favor of this bill. Make it sound human.",
    },
    {
      label: "Testimony Against",
      query:
        "Give me verbatim testimony I can read to testify against this bill. Make it sound human.",
    },
    {
      label: "Downstream Effects",
      query:
        "Are there any potential unintended downstream effects of this bill?",
    },
  ],
  speed: [
    {
      label: "Points For",
      query: "Give me 5 talking points in favor of the bill",
    },
    {
      label: "Points Against",
      query: "Give me 5 talking points against this bill",
    },
    {
      label: "Do I like?",
      query: "Am I for or against this bill as a representative of",
    },
    {
      label: "Effect",
      query: "How does this specifically affect: ",
    },
  ],
};

export default recommendedQueries;
