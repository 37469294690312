import React from "react";
import { BounceLoader as Spinner } from "react-spinners";

const MainLoader = () => {
  return (
    <div className="w-full h-[calc(100vh-168.6px)]  flex justify-center items-center">
      <Spinner color="#1DB0FF" size={150} />
    </div>
  );
};

export default MainLoader;
