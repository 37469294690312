import React, { useState } from "react";
// Components
import { HomeTable, FiltersContainer } from "../../components/videoIndex";

const Home = () => {
  const [searchRes, setSearchRes] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col gap-5">
      <FiltersContainer searchRes={searchRes} setLoading={setLoading} setSearchRes={setSearchRes} />
      <div className=" bg-[#F9FAFB] border border-[#E5E7EB] px-5 py-[15px] w-full mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
          <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
            Videos History
          </h2>
        </div>
        <HomeTable searchRes={searchRes} loading={loading} setLoading={setLoading} />
      </div>
    </div>
  );
};

export default Home;
