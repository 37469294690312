import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
// Icons
import { IoPlayOutline } from "react-icons/io5";
import { CiPause1 } from "react-icons/ci";
import { MdFullscreen } from "react-icons/md";
import { useSelector } from "react-redux";

const CustomVideo = ({ start, end, url }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null); // Reference for hls.js instance
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const { timeStamp } = useSelector((state) => state.video);

  useEffect(() => {
    const isHlsStream = url?.includes(".m3u8");
    const isMp4 = url?.includes(".mp4");

    if (isHlsStream && Hls.isSupported()) {
      hlsRef.current = new Hls();
      hlsRef.current.loadSource(url);
      hlsRef.current.attachMedia(videoRef.current);
    } else if (isMp4 || videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = url;
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [url, timeStamp]);

  useEffect(() => {
    if (videoRef.current && timeStamp) {
      videoRef.current.currentTime = timeStamp;
      videoRef.current.play();
    }
  }, [timeStamp]);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video.currentTime >= parseInt(end)) video.currentTime = start;
    if (video.currentTime < parseInt(start)) video.currentTime = start;
    const currentProgress = ((video.currentTime - start) / (end - start)) * 100;
    setProgress(currentProgress);
  };

  const handleSeek = (event) => {
    const video = videoRef.current;
    const rect = event.target.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newTime = parseInt(start) + (offsetX / event.target.clientWidth) * (end - start);
    video.currentTime = newTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        // Firefox
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        // IE/Edge
        videoRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="w-full flex flex-col justify-center relative">
      {/* <video className="w-full" ref={videoRef} title="Segment Video" onTimeUpdate={handleTimeUpdate}>
        {Hls.isSupported() ? null : <source src={`${url}#t=${start},${end}`} type="application/vnd.apple.mpegurl" />}
      </video> */}
      <video className="w-full" ref={videoRef} title="segment video" onTimeUpdate={handleTimeUpdate} />

      <div className="flex mt-2 items-center">
        <button onClick={handlePlayPause} className="text-white bg-gray-400 mr-2 hover:bg-darkblue-hover p-1">
          {isPlaying ? <CiPause1 className="w-5 h-5" /> : <IoPlayOutline className="w-5 h-5" />}
        </button>
        <div className="relative w-full h-2 bg-gray-400 cursor-pointer rounded-full" onClick={handleSeek}>
          <div
            className="absolute top-0 left-0 h-full bg-darkblue-hover rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
        <span className="text-gray-600 text-sm ml-2">
          {formatTime((videoRef.current?.currentTime || start) - start) + "/" + formatTime(end - start)}
        </span>
        <button onClick={handleFullScreen} className="text-gray-500 hover:bg-gray-200 rounded ml-2">
          <MdFullscreen className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default CustomVideo;
