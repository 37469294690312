import React from "react";

const ThreeArrowsSVG = ({ secundary }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M8 6.5L12.5 11L8 15.5"
        stroke={secundary ? "white" : "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        opacity="0.2"
        d="M14.25 6.5L18.75 11L14.25 15.5"
        stroke={secundary ? "white" : "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M2 6.5L6.5 11L2 15.5"
        stroke={secundary ? "white" : "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default ThreeArrowsSVG;
