import React, { useRef, useState } from "react";
// third party
import axios from "axios";
// components
import { SyncLoader } from "react-spinners";
// icons
import { IoSend } from "react-icons/io5";
import { PiGraph } from "react-icons/pi";

const ChatBox = ({ videoId }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const messageContainerRef = useRef();

  const sendMessage = async () => {
    const message = input.trim();
    if (message && !loading) {
      setMessages([...messages, { text: input, sender: "user" }]);
      setInput("");
      setLoading(true);
      try {
        const { data } = await axios.post("/api/video/ask", {
          query: message,
          videoId,
        });
        console.log(data);
        setLoading(false);
        setMessages([...messages, { text: input, sender: "user" }, { text: data, sender: "AI" }]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formatMessage = (message) => {
    return { __html: message.replace(/\n/g, "<br />") };
  };

  return (
    <div className="py-[15px] px-5 border border-[#E5E7EB] bg-[#F9FAFB] ">
      <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
        <span className="font-playfair text-[20px] capitalize text-darkblue font-medium leading-8 flex gap-3">
          <PiGraph className="w-6 h-6" /> Ask AI
        </span>
      </div>
      <div className="p-4 overflow-y-auto h-[350px]">
        <div className="mb-2 flex flex-col place-items-start">
          <span className="text-gray-400">AI Assistant</span>
          <p className="bg-gray-400 text-white py-2 px-3 max-w-[90%]">
            Hi! You can ask me whatever you want about the video.
          </p>
        </div>
        {messages.map((msg, index) =>
          msg.sender === "user" ? (
            <div key={index} className="mb-2 flex flex-col place-items-end">
              <span className="text-gray-400">You</span>
              <p className="bg-blue-500 text-white py-2 px-3 max-w-[90%]">{msg.text}</p>
            </div>
          ) : (
            <div key={index} className="mb-2 flex flex-col place-items-start">
              <span className="text-gray-400">AI Assistant</span>
              <p
                className="bg-gray-400 text-white py-2 px-3 max-w-[90%]"
                dangerouslySetInnerHTML={formatMessage(msg.text)}
              ></p>
            </div>
          )
        )}

        {loading && <SyncLoader className="mt-14" color="#04A7FF" />}
        <div ref={messageContainerRef}></div>
      </div>
      <div className="relative p-4 border-t border-gray-200">
        <input
          type="text"
          className="border border-[#E5E7EB] bg-white text-black text-base font-normal block py-2 px-3.5 focus:outline-none placeholder:text-black/30 w-full"
          value={input}
          placeholder="Ask something to the AI Assistant"
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
        />
        <button
          onClick={sendMessage}
          disabled={loading}
          className="mt-2 absolute right-5 top-[10px] text-blue-500 hover:bg-blue-100 p-2 rounded-lg"
        >
          <IoSend />
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
