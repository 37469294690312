import txLogo from "./assets/images/txLogo.png";
import laLogo from "./assets/images/laLogo.png";
import arLogo from "./assets/images/ARLogo.gif";
import msLogo from "./assets/images/MSLogo.gif";
import okLogo from "./assets/images/OKLogo.gif";
import ohLogo from "./assets/images/ohLogo.png";

const stateConfig = {
  la: {
    website: "http://legiscon.com/",
    logo: laLogo,
    bannerBlue: true,
    icon: "/icons/lafavicon.ico",
    tabTitle: "Legiscon - AI",
  },
  tx: {
    website: "https://www.telicon.com",
    logo: txLogo,
    bannerBlue: true,
    icon: "/icons/txfavicon.ico",
    tabTitle: "Telicon - AI",
  },
  ny: {
    website: "http://nystatewatch.net/",
    logo: msLogo,
    bannerBlue: false,
    icon: "/icons/msfavicon.ico",
    tabTitle: "NY StateWatch - AI",
  },
  ok: {
    website: "http://legisok.net/",
    logo: okLogo,
    bannerBlue: false,
    icon: "/icons/okfavicon.ico",
    tabTitle: "LegisOk - AI",
  },
  ms: {
    website: "http://msstatewatch.net/",
    logo: msLogo,
    bannerBlue: false,
    icon: "/icons/msfavicon.ico",
    tabTitle: "MS StateWatch - AI",
  },
  ar: {
    website: "http://ardigest.net/",
    logo: arLogo,
    bannerBlue: false,
    icon: "/icons/msfavicon.ico",
    tabTitle: "AR Digest - AI",
  },
  oh: {
    website: "https://www.legislature.ohio.gov/",
    logo: ohLogo,
    bannerBlue: true,
    icon: "/icons/liqfavicon.ico",
    tabTitle: "LegisIQ - AI",
    defaultVersion: "FH00",
  },
};

export default stateConfig;
