import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Store
import { useSelector, useDispatch } from "react-redux";
import { getVideoInfo } from "../../features/video/videoSlice";
// Components
import { TransciptContainer } from "../../components/videoIndex";
// Third party
import axios from "axios";
// Icons
import { CustomVideo } from "../../components/videoIndex";
// Spinner
import { BounceLoader } from "react-spinners";

const Share = () => {
  const dispatch = useDispatch();
  const { clipId } = useParams();
  const [clipInfo, setClipInfo] = useState();
  const [loading, setLoading] = useState(false);

  const { video, isLoading, isError } = useSelector((state) => state.video);
  const [transcript, setTranscript] = useState([]);

  useEffect(() => {
    getClipInfo();
  }, []);

  useEffect(() => {
    console.log(clipInfo);
    if (clipInfo) {
      const startIndex = clipInfo.StartPosition.split("-").map((e) => parseInt(e));
      const endIndex = clipInfo.EndPosition.split("-").map((e) => parseInt(e));

      // CREATE SUB MATRIX OF TRANSCRIPT
      if (video.transcript) {
        const aux = video?.transcript?.slice(startIndex[0], endIndex[0] + 1);
        const subTranscript = aux?.map((p, i) => {
          if (aux.length === 1) return p.slice(startIndex[1], endIndex[1] + 1);
          if (i === 0) return p.slice(startIndex[1], p.length);
          if (i === aux.length - 1) return p.slice(0, endIndex[1] + 1);
          return p;
        });
        setTranscript(subTranscript);
      }
    }
  }, [clipInfo, video]);

  const getClipInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/video/clip/${clipId}/`);
      setClipInfo(data);
      const videoId = data.VideoID;
      if (videoId) {
        dispatch(getVideoInfo(videoId));
      }
      setLoading(false);
    } catch (error) {}
  };

  if (isLoading || loading)
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <BounceLoader color="#1DB0FF" size={150} />
      </div>
    );

  if (isError) return <div>Error</div>;

  return (
    <div className="w-full flex gap-5">
      {video && (
        <>
          <div className="w-[40%] h-full overflow-hidden">
            <div className="py-[15px] px-5 border border-[#E5E7EB] bg-[#F9FAFB] mb-4">
              <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
                <span className="font-playfair text-[28px] capitalize text-darkblue font-medium leading-8">
                  Clip: <strong>{clipInfo?.Title}</strong>
                </span>
              </div>
              {clipInfo?.Description && (
                <div className="font-playfair text-lg gap-5 flex font-medium items-center text-darkblue ">
                  <span>{clipInfo?.Description}</span>
                </div>
              )}
              <CustomVideo start={clipInfo?.StartTime} end={clipInfo?.EndTime} url={video.Link} />
            </div>
          </div>
          {clipInfo?.IncludeTranscript && <TransciptContainer transcript={transcript} miniTranscript />}
        </>
      )}
    </div>
  );
};

export default Share;
