import React from "react";

const CustomInput = ({ label, type = "text", id, name, value, onChange, placeholder, className = "", ...props }) => {
  return (
    <div>
      {label && <label className="block text-sm font-medium mb-1">{label}</label>}
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`border border-[#E5E7EB] bg-[#F9FAFB] text-black min-w-[232px] text-base font-normal block w-full py-3 px-4 focus:outline-none placeholder:text-black/30 ${className}`}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
