import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Components
import { WordPopup, SearchBar } from "../videoIndex";
// store
import { setTimeStamp } from "../../features/video/videoSlice";
// Icons
import { GrCut } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";

const TransciptContainer = ({ transcript, onGoToTimeStamp, miniTranscript }) => {
  const navigate = useNavigate();
  const [showWordPopup, setShowWordPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const [selectedWord, setSelectedWord] = useState(null);
  const [clickedSpanId, setClickedSpanId] = useState(null);
  const [searcherResult, setSearcherResult] = useState([]);
  const [paragraphPosition, setParagraphPosition] = useState();
  const [isCutting, setIsCutting] = useState(false);
  const [startObj, setStartObj] = useState();
  const [endObj, setEndObj] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const transcriptContainerRef = useRef();

  const selectedText = useSelector((state) => state.video.selectedText);

  const { video } = useSelector((state) => state.video);
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToParagraph(searcherResult[paragraphPosition]?.paragraphIndex);
  }, [paragraphPosition, searcherResult]);

  useEffect(() => {
    if (startObj && endObj) {
      const startTime = startObj.startTime / 10;
      const endTime = endObj.endTime / 10;
      navigate(
        `../create-clip/${video.VideoID}?start=${Math.round(startTime)}&end=${Math.round(endTime)}&sp=${
          startObj.position
        }&ep=${endObj.position}`,
        { replace: true }
      );
    }
  }, [startObj, endObj]);

  useEffect(() => {
    if (selectedText) handleSearch(selectedText);
  }, [transcript, selectedText]);

  const onCloseWordPopup = () => {
    setShowWordPopup(false);
    if (clickedSpanId) {
      const spanElement = document.getElementById(clickedSpanId);
      if (spanElement) {
        spanElement.style.backgroundColor = "";
      }
      setClickedSpanId(null);
    }
  };

  const handleRightClick = (wordObj, event, i, j) => {
    event.preventDefault();
    if (clickedSpanId) {
      const prevSpan = document.getElementById(clickedSpanId);
      if (prevSpan) {
        prevSpan.style.backgroundColor = "";
      }
    }
    const rect = event.target.getBoundingClientRect();
    // setPopupPosition({ left: rect.left, top: rect.bottom });
    setPopupPosition({ left: rect.left, top: rect.y });
    setSelectedWord({ ...wordObj, position: `${i}-${j}` });
    setShowWordPopup(true);
    setClickedSpanId(event.target.id);
    event.target.style.backgroundColor = "#7FB3D5";
  };

  const handleSearch = (term) => {
    if (term) {
      const result = transcript?.reduce((acc, p, index) => {
        let searchWords = term.split(" ");
        let positions = [];

        for (let i = 0; i < p.length - searchWords.length + 1; i++) {
          let found = true;
          let tempPositions = [];
          for (let j = 0; j < searchWords.length; j++) {
            if (
              !p[i + j].word
                .toLowerCase()
                .replace(/[^\w\s]/g, "")
                .startsWith(searchWords[j].toLowerCase().replace(/[^\w\s]/g, ""))
            ) {
              found = false;
              break;
            }
            tempPositions.push(i + j);
          }
          if (found) {
            positions.push(...tempPositions);
          }
        }

        if (positions.length > 0) {
          acc.push({ paragraphIndex: index, positions: positions });
        }

        return acc;
      }, []);

      setParagraphPosition(0);
      setSearcherResult(result);
    } else {
      setSearcherResult([]);
    }
  };

  const scrollToParagraph = (paragraphNumber) => {
    const paragraphElement = document.getElementById(`p-${paragraphNumber}`);
    const container = transcriptContainerRef.current;
    if (paragraphElement) {
      // paragraphElement.scrollIntoView({ behavior: "smooth" });
      container.scrollTo({
        top: paragraphElement.offsetTop - container.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleChangeParagraphLocation = (direction) => {
    if (direction === "down") {
      if (paragraphPosition < searcherResult.length - 1) setParagraphPosition(paragraphPosition + 1);
    } else if (direction === "up") {
      if (paragraphPosition > 0) setParagraphPosition(paragraphPosition - 1);
    }
  };

  const handleSelectStart = () => {
    setIsCutting(true);
    setStartObj(selectedWord);
    setShowWordPopup(false);
  };

  const handleSelectEnd = () => {
    if (startObj) {
      setEndObj(selectedWord);
      setShowWordPopup(false);
    }
  };

  const handleCloseCutting = () => {
    setIsCutting(false);
    setStartObj(null);
    setEndObj(null);
  };

  const handleGoToTimeInMini = (decaSeconds) => {
    dispatch(setTimeStamp(decaSeconds / 10));
  };

  return (
    <div className="h-full max-h-screen w-[60%] flex-1 flex flex-col py-[15px] px-2.5 sm:px-5 border border-[#E5E7EB] bg-[#F9FAFB]">
      <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
        <h2
          id="Office-Information"
          className="font-playfair text-[28px] capitalize text-darkblue font-medium leading-8"
        >
          Video Transcript
        </h2>
      </div>
      {!miniTranscript && (
        <div className="flex flex-col sm:flex-row items-center gap-[15px] mb-5">
          <SearchBar
            handleSearch={handleSearch}
            counter={searcherResult.length}
            location={paragraphPosition + 1}
            onChangeLocation={handleChangeParagraphLocation}
            placeholder="Search..."
            search={searchTerm}
            setSearch={setSearchTerm}
          />
          <button
            className="py-[9px] px-8 bg-[#ECEFF4] text-base font-normal text-black transition-all duration-500 hover:bg-lightblue-hover hover:text-white"
            onClick={() => handleSearch(searchTerm)}
          >
            Search
          </button>
          <button
            className={`py-[9px] px-[13px] text-base font-normal transition-all duration-500 hover:bg-darkblue text-white ${
              isCutting ? "bg-darkblue" : "bg-[#83B2FA]"
            } p-1 rounded-sm mr-6`}
            onClick={() => setIsCutting(!isCutting)}
          >
            <GrCut className="w-6 h-6" />
          </button>
        </div>
      )}
      <div
        className="flex flex-col gap-5 px-6 py-3 border border-[#E5E7EB] bg-white overflow-y-auto overflow-x-hidden h-full relative"
        ref={transcriptContainerRef}
      >
        {transcript?.map((paragraph, i) => (
          <React.Fragment key={i}>
            {paragraph.length > 0 && (
              <p className="w-full flex flex-wrap" key={`p-${i}`} id={`p-${i}`}>
                {paragraph.map((wordObj, j) => {
                  const isHighlighted = searcherResult?.some(
                    (item) => item.paragraphIndex === i && item.positions.includes(j)
                  );
                  return (
                    <span
                      key={`span-${i}-${j}`}
                      id={`span-${i}-${j}`}
                      className={`"whitespace-pre-wrap hover:bg-blue-100 cursor-pointer px-1 relative ${
                        isHighlighted ? "bg-blue-200" : ""
                      }`}
                      onClick={(e) =>
                        isCutting
                          ? handleRightClick(wordObj, e, i, j)
                          : miniTranscript
                          ? handleGoToTimeInMini(wordObj.startTime)
                          : onGoToTimeStamp(wordObj.startTime)
                      }
                      onContextMenu={(e) => !miniTranscript && handleRightClick(wordObj, e, i, j)}
                    >
                      {wordObj.word}
                    </span>
                  );
                })}
              </p>
            )}
          </React.Fragment>
        ))}
        {showWordPopup && (
          <div
            style={{
              left: popupPosition.left,
              top: popupPosition.top + 30,
              position: "fixed",
              zIndex: "50",
            }}
          >
            <WordPopup
              onClose={onCloseWordPopup}
              onGoToTimeStamp={() => onGoToTimeStamp(selectedWord.startTime)}
              handleSelectStart={handleSelectStart}
              handleSelectEnd={handleSelectEnd}
            />
          </div>
        )}
      </div>
      {isCutting && (
        <div className="fixed flex flex-col gap-2 bottom-0 right-0 bg-sky-100 shadow-2xl py-5 px-9 text-gray-700">
          <span className="flex gap-2">
            <GrCut className="w-6 h-6 text-blue-500" /> Click the transcript above to select the clip's start and end
            times
          </span>
          <div className="w-full flex gap-8 ml-[35px]">
            <span className="flex items-center gap-2">
              Star time {startObj ? <MdCheckBox className="text-blue-500" /> : <MdOutlineCheckBoxOutlineBlank />}
            </span>
            <span className="flex items-center gap-2">
              End time {endObj ? <MdCheckBox className="text-blue-500" /> : <MdOutlineCheckBoxOutlineBlank />}
            </span>
          </div>
          <button
            className="absolute text-gray-400 top-1 right-1 rounded-xl hover:bg-gray-200 hover:bg-opacity-35 p-1"
            onClick={handleCloseCutting}
          >
            <IoClose className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TransciptContainer;
