import axios from "axios";
import { store } from "./app/store";

axios.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.token) {
      config.headers["Authorization"] = `${user.token}`;
    }

    const state = store.getState();
    const prefix = state.user?.state;

    if (prefix && !config.url.includes(`/${prefix}/`)) {
      const urlParts = config.url.split("/api/");
      config.url = `/api/${prefix}/${urlParts[1]}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const user = JSON.parse(localStorage.getItem("user"));
      console.error("Unauthorized: ", error.response.data.message);
      if (user?.searchTerm) window.location.href = `/${user.state}/search/${user.searchTerm}`;
    } else {
      console.error("Response Error:", error);
    }
    return Promise.reject(error);
  }
);
