import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getLobbyistById } from "../features/lobby/lobbySlice";
import LobbyistClients from "../components/LobbyistClients";
import thumbnail from "../assets/images/thumbnail-img.png";
import MainLoader from "../components/Structures/MainLoader";

const Lobbyist = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, lobbyist } = useSelector((state) => state.lobby);
  const [clientsWithPaymentRange, setClientsWithPaymentRange] = useState([]);
  const [paymentRange, setPaymentRange] = useState({ low: null, high: null });

  useEffect(() => {
    if (id) {
      dispatch(getLobbyistById(id));
    }
  }, [id, dispatch, nav]);

  useEffect(() => {
    const calculatePaymentRange = () => {
      if (lobbyist.length === 0) return;
      const lobbyistData = lobbyist[0];
      if (lobbyistData && lobbyistData.clients) {
        let totalLow = 0;
        let totalHigh = 0;

        lobbyistData.clients.forEach((client) => {
          const category = getPaymentCategory(client.RepresentationPaymentCategory);
          if (category) {
            totalLow += category.low;
            totalHigh += category.high;
          }
        });

        setPaymentRange({
          low: totalLow,
          high: totalHigh,
        });

        const enrichedClients = lobbyistData.clients.map((client) => {
          const category = getPaymentCategory(client.RepresentationPaymentCategory);
          return {
            ...client,
            PaymentRangeLabel: category ? category.label : "Unknown category",
          };
        });

        setClientsWithPaymentRange(enrichedClients);
      }
    };

    const getPaymentCategory = (paymentCategory) => {
      const paymentCategories = {
        1: { low: 0, high: 24999, label: "$0 - $24,999" },
        2: { low: 25000, high: 49999, label: "$25,000 - $49,999" },
        3: { low: 50000, high: 99999, label: "$50,000 - $99,999" },
        4: { low: 100000, high: 249999, label: "$100,000 - $249,999" },
        5: { low: 250000, high: Infinity, label: "$250,000 or more" },
      };
      return paymentCategories[paymentCategory];
    };

    calculatePaymentRange();
  }, [lobbyist]);

  if (isLoading) return <MainLoader />;

  const lobbyistData = lobbyist[0];

  return (
    <>
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] 2xl:p-8 xl:p-6 p-5 mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-8 pb-8">
          {lobbyistData ? (
            <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
              {lobbyistData.LobbyistTitle} {lobbyistData.LobbyistFirstName} {lobbyistData.LobbyistMiddleName}{" "}
              {lobbyistData.LobbyistLastName}
            </h2>
          ) : (
            <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">No Lobyist found.</h2>
          )}
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-start justify-center gap-12">
          <div className="block">
            <img src={lobbyistData?.imageUrl || thumbnail} alt="Lobbyist" className="lobbyistImage" />
          </div>
          <div className="block flex-1">
            <h3 className="lg:text-left text-center  text-[22px] font-medium font-playfair lg:mb-[40px] mb-5">
              Registered Branches {lobbyistData?.LobbyistRegisteredBranches}
            </h3>
            <div className="relative">
              <h4 className="lg:text-left text-center text-black text-[22px] font-medium font-playfair">
                Address: {lobbyistData?.LobbyistStreet}, {lobbyistData?.LobbyistCity}, {lobbyistData?.LobbyistState}{" "}
                {lobbyistData?.LobbyistZip}
              </h4>
              <p className="lg:text-left text-center text-black text-[22px] font-medium font-playfair">
                Phone: {lobbyistData?.LobbyistPhone}
              </p>
              <p className="lg:text-left text-center text-black text-[22px] font-medium font-playfair">
                Clients: {lobbyistData?.clients ? lobbyistData?.clients.length : 0}
              </p>
              {/*<p>
              Compensation Range: ${paymentRange.low?.toLocaleString()} - $
              {paymentRange.high?.toLocaleString()}
              </p>*/}
            </div>
          </div>
        </div>
      </div>

      {lobbyistData && lobbyistData.clients && <LobbyistClients clients={clientsWithPaymentRange} />}
    </>
  );
};

export default Lobbyist;
