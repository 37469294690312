import React, { useEffect } from "react";
import SearchBar from "../components/SearchBar";
import ReportTable from "../components/ReportTable";
import { useSelector, useDispatch } from "react-redux";
import { getSearchData } from "../features/lobby/lobbySlice";
import ThreeArrowsSVG from "../components/SVGs/ThreeArrowsSVG";
import MainLoader from "../components/Structures/MainLoader";

const Home = () => {
  const dispatch = useDispatch();

  const lobby = useSelector((state) => state.lobby);
  const { isLoading } = lobby;

  useEffect(() => {
    dispatch(getSearchData());
  }, []);

  if (isLoading) return <MainLoader />;

  return (
    <>
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5 w-full mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5 flex items-center justify-between">
          <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
            Lobby
          </h2>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black">
            Search
            <ThreeArrowsSVG />
          </div>
          <div className="flex flex-col lg:flex-row w-full items-center gap-[15px]">
            <SearchBar lobby={lobby} />
          </div>
        </div>
      </div>

      <ReportTable report={lobby.lobby.report} />
    </>
  );
};
export default Home;
