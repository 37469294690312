const PrintQueryLog = () => {
  const printContent = document.getElementById("queryLog");
  if (!printContent) {
    console.error("Print content not available.");
    return;
  }

  const content = printContent.innerHTML;
  const printWindow = window.open("", "", "height=800,width=1000");
  printWindow.document.write("<html><head><title>Query Log</title>");
  // Enhanced styles for the print window
  printWindow.document.write(`
      <style>
        body {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          margin: 20px;
          color: #333;
          background: #f0f0f0;
        }
        .query-item {
          background: white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          margin-bottom: 20px;
          padding: 20px;
          border-radius: 8px;
        }
        .query-item p {
          margin: 10px 0;
          line-height: 1.6;
        }
        .query-item .query-time {
          font-weight: bold;
          color: #0056b3;
        }
        .query-item .query-text {
          color: #333;
        }
        h2 {
          color: #0056b3;
        }
        .QueryBtnBox, .printBox {
          display: none;
        }
      </style>
    `);
  printWindow.document.write("</head><body>");
  printWindow.document.write("<h2>Query Log</h2>"); // Add a heading to make it clear what's being printed
  printWindow.document.write(content);
  printWindow.document.write("</body></html>");
  printWindow.document.close(); // Close the document for writing, ready for printing
  setTimeout(() => {
    printWindow.print(); // Delay the print command to ensure styles are applied
    printWindow.close(); // Close the print window after printing
  }, 250);
};

export default PrintQueryLog;
