import React from "react";
// Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// utils
import { formatTime } from "../../utils";
// Icons
import { IoCalendarClearOutline } from "react-icons/io5";
import { TbClockPlay } from "react-icons/tb";
import { setSelectedText } from "../../features/video/videoSlice";

const SearchTable = ({ searchList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnHighlightClick = (videoID, text) => {
    dispatch(setSelectedText(text.replace(/<\/?strong>/gi, "")));
    navigate(`${videoID}`);
  };

  return (
    <table className="w-full text-left rtl:text-right  rounded-xl" id="search-table">
      <thead>
        <tr className="bg-[#ECEFF4] w-full">
          <th scope="col" className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
            Date
          </th>
          <th scope="col" className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
            Title
          </th>
          <th scope="col" className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
            Chamber
          </th>
          <th scope="col" className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
            Legislature
          </th>
          <th scope="col" className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
            Duration
          </th>
        </tr>
      </thead>
      <tbody>
        {searchList.length > 0 &&
          searchList.map(({ video, highlight }, i) => (
            <React.Fragment key={video.VideoID}>
              <tr className={`bg-white border-t border-gray-400 `} key={video.VideoID}>
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap flex items-center gap-2">
                  <IoCalendarClearOutline className="text-gray-400 w-4 h-4" />
                  {new Date(video.Date).toLocaleDateString()}
                </th>
                <td className="px-6 py-4 font-medium text-lg">{video.Committee}</td>
                <td className="px-6 py-4">{video.Chamber === "H" ? "House" : "Senate"}</td>
                <td className="px-6 py-4">
                  <span className=" bg-gray-100 px-2 py-1">{video.Legislature || "no legislature"}</span>
                </td>
                <td className="px-6 py-4 flex items-center gap-1">
                  <TbClockPlay className="text-gray-400 w-5 h-5" />
                  {formatTime(video.Duration)}
                </td>
              </tr>
              <tr>
                <td colSpan="5">
                  <div className="px-6 py-4 bg-gray-50 flex flex-col gap-2">
                    {highlight &&
                      highlight["transcript"]?.map((text, i) => (
                        <span
                          key={i}
                          className="hover:underline cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: "..." + text + "...",
                          }}
                          onClick={() => handleOnHighlightClick(video.VideoID, text)}
                        />
                      ))}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default SearchTable;
